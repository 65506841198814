@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ef476f;
  --secondary: #007a9b;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --ideafactory-admin: #404040;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Lato", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007a9b;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #006682;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #111111; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 2px;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col--sidebar, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col--content,
.col--content-small, .col--actionbar,
.col--actionbar-small, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4, .col--sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8, .col--content,
  .col--content-small, .col--actionbar,
  .col--actionbar-small {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #333333; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #333333;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fbcbd7; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f79fb4; }

.table-hover .table-primary:hover {
  background-color: #f9b3c5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #f9b3c5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8dae3; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7abacb; }

.table-hover .table-secondary:hover {
  background-color: #a6d0dc; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #a6d0dc; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-ideafactory-admin,
.table-ideafactory-admin > th,
.table-ideafactory-admin > td {
  background-color: #cacaca; }

.table-ideafactory-admin th,
.table-ideafactory-admin td,
.table-ideafactory-admin thead th,
.table-ideafactory-admin tbody + tbody {
  border-color: #9c9c9c; }

.table-hover .table-ideafactory-admin:hover {
  background-color: #bdbdbd; }
  .table-hover .table-ideafactory-admin:hover > td,
  .table-hover .table-ideafactory-admin:hover > th {
    background-color: #bdbdbd; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #f9bccb;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
  .form-control::placeholder {
    color: #b4b4b4;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #333333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 2px; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 2px; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #333333;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #333333;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 71, 111, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #ef476f;
  border-color: #ef476f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #ec2453;
    border-color: #eb184a; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(241, 99, 133, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #ef476f;
    border-color: #ef476f; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #eb184a;
    border-color: #e31445; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 99, 133, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #007a9b;
  border-color: #007a9b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #005c75;
    border-color: #005268; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 142, 170, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #007a9b;
    border-color: #007a9b; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #005268;
    border-color: #00485b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 142, 170, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light, .btn-default {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover, .btn-default:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-default:focus, .btn-light.focus, .focus.btn-default {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .disabled.btn-default, .btn-light:disabled, .btn-default:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle,
  .show > .dropdown-toggle.btn-default {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus,
    .show > .dropdown-toggle.btn-default:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-ideafactory-admin {
  color: #fff;
  background-color: #404040;
  border-color: #404040;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-ideafactory-admin:hover {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #272727; }
  .btn-ideafactory-admin:focus, .btn-ideafactory-admin.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(93, 93, 93, 0.5); }
  .btn-ideafactory-admin.disabled, .btn-ideafactory-admin:disabled {
    color: #fff;
    background-color: #404040;
    border-color: #404040; }
  .btn-ideafactory-admin:not(:disabled):not(.disabled):active, .btn-ideafactory-admin:not(:disabled):not(.disabled).active,
  .show > .btn-ideafactory-admin.dropdown-toggle {
    color: #fff;
    background-color: #272727;
    border-color: #202020; }
    .btn-ideafactory-admin:not(:disabled):not(.disabled):active:focus, .btn-ideafactory-admin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ideafactory-admin.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(93, 93, 93, 0.5); }

.btn-outline-primary {
  color: #ef476f;
  border-color: #ef476f; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #ef476f;
    border-color: #ef476f; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 71, 111, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ef476f;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #ef476f;
    border-color: #ef476f; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(239, 71, 111, 0.5); }

.btn-outline-secondary {
  color: #007a9b;
  border-color: #007a9b; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #007a9b;
    border-color: #007a9b; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 155, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #007a9b;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #007a9b;
    border-color: #007a9b; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 122, 155, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-ideafactory-admin {
  color: #404040;
  border-color: #404040; }
  .btn-outline-ideafactory-admin:hover {
    color: #fff;
    background-color: #404040;
    border-color: #404040; }
  .btn-outline-ideafactory-admin:focus, .btn-outline-ideafactory-admin.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }
  .btn-outline-ideafactory-admin.disabled, .btn-outline-ideafactory-admin:disabled {
    color: #404040;
    background-color: transparent; }
  .btn-outline-ideafactory-admin:not(:disabled):not(.disabled):active, .btn-outline-ideafactory-admin:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ideafactory-admin.dropdown-toggle {
    color: #fff;
    background-color: #404040;
    border-color: #404040; }
    .btn-outline-ideafactory-admin:not(:disabled):not(.disabled):active:focus, .btn-outline-ideafactory-admin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ideafactory-admin.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007a9b;
  text-decoration: none; }
  .btn-link:hover {
    color: #006682;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #333333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ef476f; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 2px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ef476f;
    background-color: #ef476f;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #f9bccb; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fdebf0;
    border-color: #fdebf0;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 2px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ef476f;
  background-color: #ef476f;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(239, 71, 111, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(239, 71, 111, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(239, 71, 111, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(239, 71, 111, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #f9bccb;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #f9bccb;
    box-shadow: 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 2px 2px 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ef476f;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fdebf0; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ef476f;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fdebf0; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #ef476f;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fdebf0; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #111111;
    background-color: #FFF;
    border-color: #dee2e6 #dee2e6 #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 2px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ef476f; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.975rem 0; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #111111; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #111111; }

.navbar-light .navbar-nav .nav-link {
  color: #111111; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #111111; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #111111; }

.navbar-light .navbar-toggler {
  color: #111111;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23111111' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #111111; }
  .navbar-light .navbar-text a {
    color: #111111; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #111111; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: 1px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 1px;
    border-top-right-radius: 1px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px; }

.card-body {
  flex: 1 1 auto;
  padding: 1.125rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.125rem; }

.card-header {
  padding: 0.75rem 1.125rem;
  margin-bottom: 0;
  background-color: #FFF;
  border-bottom: 1px solid transparent; }
  .card-header:first-child {
    border-radius: calc(1px - 1px) calc(1px - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.125rem;
  background-color: #FFF;
  border-top: 1px solid transparent; }
  .card-footer:last-child {
    border-radius: 0 0 calc(1px - 1px) calc(1px - 1px); }

.card-header-tabs {
  margin-right: -0.5625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.5625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5625rem;
  margin-left: -0.5625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(1px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(1px - 1px);
  border-top-right-radius: calc(1px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(1px - 1px);
  border-bottom-left-radius: calc(1px - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007a9b;
  background-color: #FFF;
  border: 1px solid #FFF; }
  .page-link:hover {
    z-index: 2;
    color: #006682;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  background-color: #007a9b;
  border-color: #007a9b; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #ef476f; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #eb184a; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 71, 111, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #007a9b; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #005268; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 155, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-ideafactory-admin, .badge-campaign {
  color: #fff;
  background-color: #404040; }
  a.badge-ideafactory-admin:hover, a.badge-campaign:hover, a.badge-ideafactory-admin:focus, a.badge-campaign:focus {
    color: #fff;
    background-color: #272727; }
  a.badge-ideafactory-admin:focus, a.badge-campaign:focus, a.badge-ideafactory-admin.focus, a.focus.badge-campaign {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 2px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 2px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #7c253a;
  background-color: #fcdae2;
  border-color: #fbcbd7; }
  .alert-primary hr {
    border-top-color: #f9b3c5; }
  .alert-primary .alert-link {
    color: #551928; }

.alert-secondary {
  color: #003f51;
  background-color: #cce4eb;
  border-color: #b8dae3; }
  .alert-secondary hr {
    border-top-color: #a6d0dc; }
  .alert-secondary .alert-link {
    color: #00171e; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-ideafactory-admin {
  color: #212121;
  background-color: #d9d9d9;
  border-color: #cacaca; }
  .alert-ideafactory-admin hr {
    border-top-color: #bdbdbd; }
  .alert-ideafactory-admin .alert-link {
    color: #080808; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 2px;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ef476f;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #333333;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ef476f;
    border-color: #ef476f; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #7c253a;
  background-color: #fbcbd7; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #7c253a;
    background-color: #f9b3c5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #7c253a;
    border-color: #7c253a; }

.list-group-item-secondary {
  color: #003f51;
  background-color: #b8dae3; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #003f51;
    background-color: #a6d0dc; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #003f51;
    border-color: #003f51; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-ideafactory-admin {
  color: #212121;
  background-color: #cacaca; }
  .list-group-item-ideafactory-admin.list-group-item-action:hover, .list-group-item-ideafactory-admin.list-group-item-action:focus {
    color: #212121;
    background-color: #bdbdbd; }
  .list-group-item-ideafactory-admin.list-group-item-action.active {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #FFF;
  border-radius: 2px;
  box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.15);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #FFF;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #FFF;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-content {
    box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.15); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 2px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.15); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 2px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 2px 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 2px 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #111111;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(2px - 1px);
  border-top-right-radius: calc(2px - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 1rem;
  color: #333333; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 9px;
    height: 9px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #b5b5b5;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ef476f !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #eb184a !important; }

.bg-secondary, .actionbar, #notifications-count, .card-ideas__likes, .card-ideas__image, .ideas-footer, .sidebar-countdown-text, .assessments-total,
.community-ideas {
  background-color: #007a9b !important; }

a.bg-secondary:hover, a.actionbar:hover, a#notifications-count:hover, a.card-ideas__likes:hover, a.card-ideas__image:hover, a.ideas-footer:hover, a.sidebar-countdown-text:hover, a.assessments-total:hover,
a.community-ideas:hover, a.bg-secondary:focus, a.actionbar:focus, a#notifications-count:focus, a.card-ideas__likes:focus, a.card-ideas__image:focus, a.ideas-footer:focus, a.sidebar-countdown-text:focus, a.assessments-total:focus,
a.community-ideas:focus,
button.bg-secondary:hover,
button.actionbar:hover,
button#notifications-count:hover,
button.card-ideas__likes:hover,
button.card-ideas__image:hover,
button.ideas-footer:hover,
button.sidebar-countdown-text:hover,
button.assessments-total:hover,
button.community-ideas:hover,
button.bg-secondary:focus,
button.actionbar:focus,
button#notifications-count:focus,
button.card-ideas__likes:focus,
button.card-ideas__image:focus,
button.ideas-footer:focus,
button.sidebar-countdown-text:focus,
button.assessments-total:focus,
button.community-ideas:focus {
  background-color: #005268 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-ideafactory-admin, .sidebar-box-header--admin, .admin-menu {
  background-color: #404040 !important; }

a.bg-ideafactory-admin:hover, a.sidebar-box-header--admin:hover, a.admin-menu:hover, a.bg-ideafactory-admin:focus, a.sidebar-box-header--admin:focus, a.admin-menu:focus,
button.bg-ideafactory-admin:hover,
button.sidebar-box-header--admin:hover,
button.admin-menu:hover,
button.bg-ideafactory-admin:focus,
button.sidebar-box-header--admin:focus,
button.admin-menu:focus {
  background-color: #272727 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ef476f !important; }

.border-secondary {
  border-color: #007a9b !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-ideafactory-admin {
  border-color: #404040 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 2px !important; }

.rounded {
  border-radius: 2px !important; }

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-lg {
  border-radius: 2px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end, .navbar-nav {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100, .col--award {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1, .card-campaign__subtitle,
.my-1,
.menu-separator {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .award-label,
.my-1,
.menu-separator {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .card-ideas__subtitle, .card-news__date, .pinned-news__date, .sidebar-news__date,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .award-label,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3, .card-ideas__title, .idea__heading-2, .criteria, .card-campaign__title, .card-campaign__subtitle,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4, .idea__heading-2,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5,
.my-5 {
  margin-top: 2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2rem !important; }

.mb-5, .card--admin-dashboard,
.my-5 {
  margin-bottom: 2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2rem !important; }

.m-6 {
  margin: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.m-7 {
  margin: 4rem !important; }

.mt-7,
.my-7 {
  margin-top: 4rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 6rem !important; }

.mt-9,
.my-9 {
  margin-top: 6rem !important; }

.mr-9,
.mx-9 {
  margin-right: 6rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 6rem !important; }

.ml-9,
.mx-9 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1,
.sidebar-box-header,
.admin-menu-item-sub,
.menu-separator {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1,
.sidebar-box-header,
.admin-menu-item-sub,
.menu-separator {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2, .admin-menu-item > .fa, .admin-menu-item-sub > .fa,
.px-2,
.col--content,
.col--content-small,
.col--sidebar,
.col--actionbar,
.col--actionbar-small,
.col--award {
  padding-right: 0.5rem !important; }

.pb-2, .criteria,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2,
.col--content,
.col--content-small,
.col--sidebar,
.col--actionbar,
.col--actionbar-small,
.col--award {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3, .awards,
.py-3,
.col--content,
.col--content-small,
.col--sidebar,
.admin-menu-item {
  padding-top: 1rem !important; }

.pr-3, .admin-menu-item-sub,
.px-3,
.admin-menu-header,
.admin-menu-item {
  padding-right: 1rem !important; }

.pb-3,
.py-3,
.col--content,
.col--content-small,
.col--sidebar,
.admin-menu-item {
  padding-bottom: 1rem !important; }

.pl-3, .notification--evaluation,
.px-3,
.admin-menu-header,
.admin-menu-item {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4,
.evaluation_idea-details,
.admin-menu-header {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4,
.btn--login {
  padding-right: 1.5rem !important; }

.pb-4, .awards,
.py-4,
.evaluation_idea-details,
.admin-menu-header {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4,
.btn--login {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5,
.py-5,
.actionbar,
.sidebar-box,
.count {
  padding-top: 2rem !important; }

.pr-5,
.px-5,
.sidebar-box {
  padding-right: 2rem !important; }

.pb-5,
.py-5,
.actionbar,
.sidebar-box,
.count {
  padding-bottom: 2rem !important; }

.pl-5,
.px-5,
.sidebar-box {
  padding-left: 2rem !important; }

.p-6 {
  padding: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pr-6, .menu-separator,
.px-6,
.evaluation_idea-details {
  padding-right: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.pl-6, .admin-menu-item-sub, .menu-separator,
.px-6,
.evaluation_idea-details {
  padding-left: 3rem !important; }

.p-7 {
  padding: 4rem !important; }

.pt-7,
.py-7 {
  padding-top: 4rem !important; }

.pr-7,
.px-7 {
  padding-right: 4rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4rem !important; }

.pl-7,
.px-7 {
  padding-left: 4rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 6rem !important; }

.pt-9,
.py-9 {
  padding-top: 6rem !important; }

.pr-9,
.px-9 {
  padding-right: 6rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 6rem !important; }

.pl-9,
.px-9 {
  padding-left: 6rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -2rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -2rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important; }

.m-n6 {
  margin: -3rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -3rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -3rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -3rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -3rem !important; }

.m-n7 {
  margin: -4rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -4rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -4rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -4rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -4rem !important; }

.m-n8 {
  margin: -5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important; }

.m-n9 {
  margin: -6rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -6rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -6rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -6rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 6rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 6rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 6rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 6rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 6rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 6rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 6rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 6rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 6rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -2rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important; }
  .m-sm-n6 {
    margin: -3rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3rem !important; }
  .m-sm-n7 {
    margin: -4rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -4rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -4rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -4rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -4rem !important; }
  .m-sm-n8 {
    margin: -5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important; }
  .m-sm-n9 {
    margin: -6rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -6rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -6rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -6rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 6rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 6rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 6rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 6rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3,
  .col--content,
  .col--content-small,
  .col--sidebar,
  .col--actionbar,
  .col--actionbar-small {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3,
  .col--content,
  .col--content-small,
  .col--sidebar,
  .col--actionbar,
  .col--actionbar-small {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 6rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 6rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 6rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 6rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 6rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -2rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important; }
  .m-md-n6 {
    margin: -3rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3rem !important; }
  .m-md-n7 {
    margin: -4rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -4rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -4rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -4rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -4rem !important; }
  .m-md-n8 {
    margin: -5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important; }
  .m-md-n9 {
    margin: -6rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -6rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -6rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -6rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 6rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 6rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 6rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 6rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4, .col--content,
  .col--content-small, .col--actionbar,
  .col--actionbar-small,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .pt-lg-7,
  .py-lg-7,
  .col--content,
  .col--content-small,
  .col--sidebar {
    padding-top: 4rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4rem !important; }
  .pb-lg-7,
  .py-lg-7,
  .col--content,
  .col--content-small,
  .col--sidebar {
    padding-bottom: 4rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 6rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 6rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 6rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 6rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 6rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -2rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important; }
  .m-lg-n6 {
    margin: -3rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3rem !important; }
  .m-lg-n7 {
    margin: -4rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -4rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -4rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -4rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -4rem !important; }
  .m-lg-n8 {
    margin: -5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important; }
  .m-lg-n9 {
    margin: -6rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -6rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -6rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -6rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .m-xl-7 {
    margin: 4rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 6rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 6rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 6rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 6rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4,
  .col--sidebar {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4,
  .col--sidebar {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important; }
  .pl-xl-5, .col--content, .col--actionbar,
  .px-xl-5 {
    padding-left: 2rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .p-xl-7 {
    padding: 4rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4rem !important; }
  .pl-xl-7,
  .col--content-small,
  .col--actionbar-small,
  .px-xl-7 {
    padding-left: 4rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8, .col--content, .col--actionbar,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 6rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 6rem !important; }
  .pr-xl-9,
  .col--content-small,
  .col--actionbar-small,
  .px-xl-9 {
    padding-right: 6rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 6rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 6rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -2rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important; }
  .m-xl-n6 {
    margin: -3rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3rem !important; }
  .m-xl-n7 {
    margin: -4rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -4rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -4rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -4rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -4rem !important; }
  .m-xl-n8 {
    margin: -5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important; }
  .m-xl-n9 {
    margin: -6rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -6rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -6rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -6rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center, .sidebar-box-header, .count {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase, .admin-menu-header {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold, .idea__profile-name {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white, .sidebar-box-header, .sidebar-box--collaboration, .sidebar-box--countdown, #notifications-count, .card-ideas__likes, .badge-campaign, .sidebar-countdown-text, .card--active-campaign, .admin-menu-header, .admin-menu-item, .admin-menu-item-sub, .admin-menu-item-sub:hover .fa, .count {
  color: #fff !important; }

.text-primary, .admin-menu-item-sub.active-path .fa {
  color: #ef476f !important; }

a.text-primary:hover, .admin-menu-item-sub.active-path a.fa:hover, a.text-primary:focus, .admin-menu-item-sub.active-path a.fa:focus {
  color: #d71341 !important; }

.text-secondary, .notification__close, .badge-ideafactory-grey-lightest {
  color: #007a9b !important; }

a.text-secondary:hover, a.notification__close:hover, a.badge-ideafactory-grey-lightest:hover, a.text-secondary:focus, a.notification__close:focus, a.badge-ideafactory-grey-lightest:focus {
  color: #003e4f !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-ideafactory-admin {
  color: #404040 !important; }

a.text-ideafactory-admin:hover, a.text-ideafactory-admin:focus {
  color: #1a1a1a !important; }

.text-body {
  color: #333333 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1024px !important; }
  .container {
    min-width: 1024px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("~font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("~font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("~font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("~font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("~font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("~font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-fw {
  width: 1.28571em;
  text-align: center; }

.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center; }
  .fa-li.fa-lg {
    left: -1.85714em; }

.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eee;
  border-radius: .1em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right {
  margin-left: .3em; }

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right; }

.pull-left {
  float: left; }

.fa.pull-left {
  margin-right: .3em; }

.fa.pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: ""; }

.fa-music:before {
  content: ""; }

.fa-search:before {
  content: ""; }

.fa-envelope-o:before {
  content: ""; }

.fa-heart:before {
  content: ""; }

.fa-star:before {
  content: ""; }

.fa-star-o:before {
  content: ""; }

.fa-user:before {
  content: ""; }

.fa-film:before {
  content: ""; }

.fa-th-large:before {
  content: ""; }

.fa-th:before {
  content: ""; }

.fa-th-list:before {
  content: ""; }

.fa-check:before {
  content: ""; }

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: ""; }

.fa-search-plus:before {
  content: ""; }

.fa-search-minus:before {
  content: ""; }

.fa-power-off:before {
  content: ""; }

.fa-signal:before {
  content: ""; }

.fa-gear:before,
.fa-cog:before {
  content: ""; }

.fa-trash-o:before {
  content: ""; }

.fa-home:before {
  content: ""; }

.fa-file-o:before {
  content: ""; }

.fa-clock-o:before {
  content: ""; }

.fa-road:before {
  content: ""; }

.fa-download:before {
  content: ""; }

.fa-arrow-circle-o-down:before {
  content: ""; }

.fa-arrow-circle-o-up:before {
  content: ""; }

.fa-inbox:before {
  content: ""; }

.fa-play-circle-o:before {
  content: ""; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

.fa-refresh:before {
  content: ""; }

.fa-list-alt:before {
  content: ""; }

.fa-lock:before {
  content: ""; }

.fa-flag:before {
  content: ""; }

.fa-headphones:before {
  content: ""; }

.fa-volume-off:before {
  content: ""; }

.fa-volume-down:before {
  content: ""; }

.fa-volume-up:before {
  content: ""; }

.fa-qrcode:before {
  content: ""; }

.fa-barcode:before {
  content: ""; }

.fa-tag:before {
  content: ""; }

.fa-tags:before {
  content: ""; }

.fa-book:before {
  content: ""; }

.fa-bookmark:before {
  content: ""; }

.fa-print:before {
  content: ""; }

.fa-camera:before {
  content: ""; }

.fa-font:before {
  content: ""; }

.fa-bold:before {
  content: ""; }

.fa-italic:before {
  content: ""; }

.fa-text-height:before {
  content: ""; }

.fa-text-width:before {
  content: ""; }

.fa-align-left:before {
  content: ""; }

.fa-align-center:before {
  content: ""; }

.fa-align-right:before {
  content: ""; }

.fa-align-justify:before {
  content: ""; }

.fa-list:before {
  content: ""; }

.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

.fa-indent:before {
  content: ""; }

.fa-video-camera:before {
  content: ""; }

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: ""; }

.fa-pencil:before {
  content: ""; }

.fa-map-marker:before {
  content: ""; }

.fa-adjust:before {
  content: ""; }

.fa-tint:before {
  content: ""; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

.fa-share-square-o:before {
  content: ""; }

.fa-check-square-o:before {
  content: ""; }

.fa-arrows:before {
  content: ""; }

.fa-step-backward:before {
  content: ""; }

.fa-fast-backward:before {
  content: ""; }

.fa-backward:before {
  content: ""; }

.fa-play:before {
  content: ""; }

.fa-pause:before {
  content: ""; }

.fa-stop:before {
  content: ""; }

.fa-forward:before {
  content: ""; }

.fa-fast-forward:before {
  content: ""; }

.fa-step-forward:before {
  content: ""; }

.fa-eject:before {
  content: ""; }

.fa-chevron-left:before {
  content: ""; }

.fa-chevron-right:before {
  content: ""; }

.fa-plus-circle:before {
  content: ""; }

.fa-minus-circle:before {
  content: ""; }

.fa-times-circle:before {
  content: ""; }

.fa-check-circle:before {
  content: ""; }

.fa-question-circle:before {
  content: ""; }

.fa-info-circle:before {
  content: ""; }

.fa-crosshairs:before {
  content: ""; }

.fa-times-circle-o:before {
  content: ""; }

.fa-check-circle-o:before {
  content: ""; }

.fa-ban:before {
  content: ""; }

.fa-arrow-left:before {
  content: ""; }

.fa-arrow-right:before {
  content: ""; }

.fa-arrow-up:before {
  content: ""; }

.fa-arrow-down:before {
  content: ""; }

.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

.fa-expand:before {
  content: ""; }

.fa-compress:before {
  content: ""; }

.fa-plus:before {
  content: ""; }

.fa-minus:before {
  content: ""; }

.fa-asterisk:before {
  content: ""; }

.fa-exclamation-circle:before {
  content: ""; }

.fa-gift:before {
  content: ""; }

.fa-leaf:before {
  content: ""; }

.fa-fire:before {
  content: ""; }

.fa-eye:before {
  content: ""; }

.fa-eye-slash:before {
  content: ""; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

.fa-plane:before {
  content: ""; }

.fa-calendar:before {
  content: ""; }

.fa-random:before {
  content: ""; }

.fa-comment:before {
  content: ""; }

.fa-magnet:before {
  content: ""; }

.fa-chevron-up:before {
  content: ""; }

.fa-chevron-down:before {
  content: ""; }

.fa-retweet:before {
  content: ""; }

.fa-shopping-cart:before {
  content: ""; }

.fa-folder:before {
  content: ""; }

.fa-folder-open:before {
  content: ""; }

.fa-arrows-v:before {
  content: ""; }

.fa-arrows-h:before {
  content: ""; }

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

.fa-twitter-square:before {
  content: ""; }

.fa-facebook-square:before {
  content: ""; }

.fa-camera-retro:before {
  content: ""; }

.fa-key:before {
  content: ""; }

.fa-gears:before,
.fa-cogs:before {
  content: ""; }

.fa-comments:before {
  content: ""; }

.fa-thumbs-o-up:before {
  content: ""; }

.fa-thumbs-o-down:before {
  content: ""; }

.fa-star-half:before {
  content: ""; }

.fa-heart-o:before {
  content: ""; }

.fa-sign-out:before {
  content: ""; }

.fa-linkedin-square:before {
  content: ""; }

.fa-thumb-tack:before {
  content: ""; }

.fa-external-link:before {
  content: ""; }

.fa-sign-in:before {
  content: ""; }

.fa-trophy:before {
  content: ""; }

.fa-github-square:before {
  content: ""; }

.fa-upload:before {
  content: ""; }

.fa-lemon-o:before {
  content: ""; }

.fa-phone:before {
  content: ""; }

.fa-square-o:before {
  content: ""; }

.fa-bookmark-o:before {
  content: ""; }

.fa-phone-square:before {
  content: ""; }

.fa-twitter:before {
  content: ""; }

.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

.fa-github:before {
  content: ""; }

.fa-unlock:before {
  content: ""; }

.fa-credit-card:before {
  content: ""; }

.fa-feed:before,
.fa-rss:before {
  content: ""; }

.fa-hdd-o:before {
  content: ""; }

.fa-bullhorn:before {
  content: ""; }

.fa-bell:before {
  content: ""; }

.fa-certificate:before {
  content: ""; }

.fa-hand-o-right:before {
  content: ""; }

.fa-hand-o-left:before {
  content: ""; }

.fa-hand-o-up:before {
  content: ""; }

.fa-hand-o-down:before {
  content: ""; }

.fa-arrow-circle-left:before {
  content: ""; }

.fa-arrow-circle-right:before {
  content: ""; }

.fa-arrow-circle-up:before {
  content: ""; }

.fa-arrow-circle-down:before {
  content: ""; }

.fa-globe:before {
  content: ""; }

.fa-wrench:before {
  content: ""; }

.fa-tasks:before {
  content: ""; }

.fa-filter:before {
  content: ""; }

.fa-briefcase:before {
  content: ""; }

.fa-arrows-alt:before {
  content: ""; }

.fa-group:before,
.fa-users:before {
  content: ""; }

.fa-chain:before,
.fa-link:before {
  content: ""; }

.fa-cloud:before {
  content: ""; }

.fa-flask:before {
  content: ""; }

.fa-cut:before,
.fa-scissors:before {
  content: ""; }

.fa-copy:before,
.fa-files-o:before {
  content: ""; }

.fa-paperclip:before {
  content: ""; }

.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

.fa-square:before {
  content: ""; }

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

.fa-list-ul:before {
  content: ""; }

.fa-list-ol:before {
  content: ""; }

.fa-strikethrough:before {
  content: ""; }

.fa-underline:before {
  content: ""; }

.fa-table:before {
  content: ""; }

.fa-magic:before {
  content: ""; }

.fa-truck:before {
  content: ""; }

.fa-pinterest:before {
  content: ""; }

.fa-pinterest-square:before {
  content: ""; }

.fa-google-plus-square:before {
  content: ""; }

.fa-google-plus:before {
  content: ""; }

.fa-money:before {
  content: ""; }

.fa-caret-down:before {
  content: ""; }

.fa-caret-up:before {
  content: ""; }

.fa-caret-left:before {
  content: ""; }

.fa-caret-right:before {
  content: ""; }

.fa-columns:before {
  content: ""; }

.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

.fa-envelope:before {
  content: ""; }

.fa-linkedin:before {
  content: ""; }

.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

.fa-legal:before,
.fa-gavel:before {
  content: ""; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

.fa-comment-o:before {
  content: ""; }

.fa-comments-o:before {
  content: ""; }

.fa-flash:before,
.fa-bolt:before {
  content: ""; }

.fa-sitemap:before {
  content: ""; }

.fa-umbrella:before {
  content: ""; }

.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

.fa-lightbulb-o:before {
  content: ""; }

.fa-exchange:before {
  content: ""; }

.fa-cloud-download:before {
  content: ""; }

.fa-cloud-upload:before {
  content: ""; }

.fa-user-md:before {
  content: ""; }

.fa-stethoscope:before {
  content: ""; }

.fa-suitcase:before {
  content: ""; }

.fa-bell-o:before {
  content: ""; }

.fa-coffee:before {
  content: ""; }

.fa-cutlery:before {
  content: ""; }

.fa-file-text-o:before {
  content: ""; }

.fa-building-o:before {
  content: ""; }

.fa-hospital-o:before {
  content: ""; }

.fa-ambulance:before {
  content: ""; }

.fa-medkit:before {
  content: ""; }

.fa-fighter-jet:before {
  content: ""; }

.fa-beer:before {
  content: ""; }

.fa-h-square:before {
  content: ""; }

.fa-plus-square:before {
  content: ""; }

.fa-angle-double-left:before {
  content: ""; }

.fa-angle-double-right:before {
  content: ""; }

.fa-angle-double-up:before {
  content: ""; }

.fa-angle-double-down:before {
  content: ""; }

.fa-angle-left:before {
  content: ""; }

.fa-angle-right:before {
  content: ""; }

.fa-angle-up:before {
  content: ""; }

.fa-angle-down:before {
  content: ""; }

.fa-desktop:before {
  content: ""; }

.fa-laptop:before {
  content: ""; }

.fa-tablet:before {
  content: ""; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

.fa-circle-o:before {
  content: ""; }

.fa-quote-left:before {
  content: ""; }

.fa-quote-right:before {
  content: ""; }

.fa-spinner:before {
  content: ""; }

.fa-circle:before {
  content: ""; }

.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

.fa-github-alt:before {
  content: ""; }

.fa-folder-o:before {
  content: ""; }

.fa-folder-open-o:before {
  content: ""; }

.fa-smile-o:before {
  content: ""; }

.fa-frown-o:before {
  content: ""; }

.fa-meh-o:before {
  content: ""; }

.fa-gamepad:before {
  content: ""; }

.fa-keyboard-o:before {
  content: ""; }

.fa-flag-o:before {
  content: ""; }

.fa-flag-checkered:before {
  content: ""; }

.fa-terminal:before {
  content: ""; }

.fa-code:before {
  content: ""; }

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

.fa-location-arrow:before {
  content: ""; }

.fa-crop:before {
  content: ""; }

.fa-code-fork:before {
  content: ""; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: ""; }

.fa-question:before {
  content: ""; }

.fa-info:before {
  content: ""; }

.fa-exclamation:before {
  content: ""; }

.fa-superscript:before {
  content: ""; }

.fa-subscript:before {
  content: ""; }

.fa-eraser:before {
  content: ""; }

.fa-puzzle-piece:before {
  content: ""; }

.fa-microphone:before {
  content: ""; }

.fa-microphone-slash:before {
  content: ""; }

.fa-shield:before {
  content: ""; }

.fa-calendar-o:before {
  content: ""; }

.fa-fire-extinguisher:before {
  content: ""; }

.fa-rocket:before {
  content: ""; }

.fa-maxcdn:before {
  content: ""; }

.fa-chevron-circle-left:before {
  content: ""; }

.fa-chevron-circle-right:before {
  content: ""; }

.fa-chevron-circle-up:before {
  content: ""; }

.fa-chevron-circle-down:before {
  content: ""; }

.fa-html5:before {
  content: ""; }

.fa-css3:before {
  content: ""; }

.fa-anchor:before {
  content: ""; }

.fa-unlock-alt:before {
  content: ""; }

.fa-bullseye:before {
  content: ""; }

.fa-ellipsis-h:before {
  content: ""; }

.fa-ellipsis-v:before {
  content: ""; }

.fa-rss-square:before {
  content: ""; }

.fa-play-circle:before {
  content: ""; }

.fa-ticket:before {
  content: ""; }

.fa-minus-square:before {
  content: ""; }

.fa-minus-square-o:before {
  content: ""; }

.fa-level-up:before {
  content: ""; }

.fa-level-down:before {
  content: ""; }

.fa-check-square:before {
  content: ""; }

.fa-pencil-square:before {
  content: ""; }

.fa-external-link-square:before {
  content: ""; }

.fa-share-square:before {
  content: ""; }

.fa-compass:before {
  content: ""; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

.fa-euro:before,
.fa-eur:before {
  content: ""; }

.fa-gbp:before {
  content: ""; }

.fa-dollar:before,
.fa-usd:before {
  content: ""; }

.fa-rupee:before,
.fa-inr:before {
  content: ""; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

.fa-won:before,
.fa-krw:before {
  content: ""; }

.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

.fa-file:before {
  content: ""; }

.fa-file-text:before {
  content: ""; }

.fa-sort-alpha-asc:before {
  content: ""; }

.fa-sort-alpha-desc:before {
  content: ""; }

.fa-sort-amount-asc:before {
  content: ""; }

.fa-sort-amount-desc:before {
  content: ""; }

.fa-sort-numeric-asc:before {
  content: ""; }

.fa-sort-numeric-desc:before {
  content: ""; }

.fa-thumbs-up:before {
  content: ""; }

.fa-thumbs-down:before {
  content: ""; }

.fa-youtube-square:before {
  content: ""; }

.fa-youtube:before {
  content: ""; }

.fa-xing:before {
  content: ""; }

.fa-xing-square:before {
  content: ""; }

.fa-youtube-play:before {
  content: ""; }

.fa-dropbox:before {
  content: ""; }

.fa-stack-overflow:before {
  content: ""; }

.fa-instagram:before {
  content: ""; }

.fa-flickr:before {
  content: ""; }

.fa-adn:before {
  content: ""; }

.fa-bitbucket:before {
  content: ""; }

.fa-bitbucket-square:before {
  content: ""; }

.fa-tumblr:before {
  content: ""; }

.fa-tumblr-square:before {
  content: ""; }

.fa-long-arrow-down:before {
  content: ""; }

.fa-long-arrow-up:before {
  content: ""; }

.fa-long-arrow-left:before {
  content: ""; }

.fa-long-arrow-right:before {
  content: ""; }

.fa-apple:before {
  content: ""; }

.fa-windows:before {
  content: ""; }

.fa-android:before {
  content: ""; }

.fa-linux:before {
  content: ""; }

.fa-dribbble:before {
  content: ""; }

.fa-skype:before {
  content: ""; }

.fa-foursquare:before {
  content: ""; }

.fa-trello:before {
  content: ""; }

.fa-female:before {
  content: ""; }

.fa-male:before {
  content: ""; }

.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

.fa-sun-o:before {
  content: ""; }

.fa-moon-o:before {
  content: ""; }

.fa-archive:before {
  content: ""; }

.fa-bug:before {
  content: ""; }

.fa-vk:before {
  content: ""; }

.fa-weibo:before {
  content: ""; }

.fa-renren:before {
  content: ""; }

.fa-pagelines:before {
  content: ""; }

.fa-stack-exchange:before {
  content: ""; }

.fa-arrow-circle-o-right:before {
  content: ""; }

.fa-arrow-circle-o-left:before {
  content: ""; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

.fa-dot-circle-o:before {
  content: ""; }

.fa-wheelchair:before {
  content: ""; }

.fa-vimeo-square:before {
  content: ""; }

.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

.fa-plus-square-o:before {
  content: ""; }

.fa-space-shuttle:before {
  content: ""; }

.fa-slack:before {
  content: ""; }

.fa-envelope-square:before {
  content: ""; }

.fa-wordpress:before {
  content: ""; }

.fa-openid:before {
  content: ""; }

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

.fa-yahoo:before {
  content: ""; }

.fa-google:before {
  content: ""; }

.fa-reddit:before {
  content: ""; }

.fa-reddit-square:before {
  content: ""; }

.fa-stumbleupon-circle:before {
  content: ""; }

.fa-stumbleupon:before {
  content: ""; }

.fa-delicious:before {
  content: ""; }

.fa-digg:before {
  content: ""; }

.fa-pied-piper-pp:before {
  content: ""; }

.fa-pied-piper-alt:before {
  content: ""; }

.fa-drupal:before {
  content: ""; }

.fa-joomla:before {
  content: ""; }

.fa-language:before {
  content: ""; }

.fa-fax:before {
  content: ""; }

.fa-building:before {
  content: ""; }

.fa-child:before {
  content: ""; }

.fa-paw:before {
  content: ""; }

.fa-spoon:before {
  content: ""; }

.fa-cube:before {
  content: ""; }

.fa-cubes:before {
  content: ""; }

.fa-behance:before {
  content: ""; }

.fa-behance-square:before {
  content: ""; }

.fa-steam:before {
  content: ""; }

.fa-steam-square:before {
  content: ""; }

.fa-recycle:before {
  content: ""; }

.fa-automobile:before,
.fa-car:before {
  content: ""; }

.fa-cab:before,
.fa-taxi:before {
  content: ""; }

.fa-tree:before {
  content: ""; }

.fa-spotify:before {
  content: ""; }

.fa-deviantart:before {
  content: ""; }

.fa-soundcloud:before {
  content: ""; }

.fa-database:before {
  content: ""; }

.fa-file-pdf-o:before {
  content: ""; }

.fa-file-word-o:before {
  content: ""; }

.fa-file-excel-o:before {
  content: ""; }

.fa-file-powerpoint-o:before {
  content: ""; }

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

.fa-file-code-o:before {
  content: ""; }

.fa-vine:before {
  content: ""; }

.fa-codepen:before {
  content: ""; }

.fa-jsfiddle:before {
  content: ""; }

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

.fa-circle-o-notch:before {
  content: ""; }

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: ""; }

.fa-ge:before,
.fa-empire:before {
  content: ""; }

.fa-git-square:before {
  content: ""; }

.fa-git:before {
  content: ""; }

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

.fa-tencent-weibo:before {
  content: ""; }

.fa-qq:before {
  content: ""; }

.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

.fa-history:before {
  content: ""; }

.fa-circle-thin:before {
  content: ""; }

.fa-header:before {
  content: ""; }

.fa-paragraph:before {
  content: ""; }

.fa-sliders:before {
  content: ""; }

.fa-share-alt:before {
  content: ""; }

.fa-share-alt-square:before {
  content: ""; }

.fa-bomb:before {
  content: ""; }

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

.fa-tty:before {
  content: ""; }

.fa-binoculars:before {
  content: ""; }

.fa-plug:before {
  content: ""; }

.fa-slideshare:before {
  content: ""; }

.fa-twitch:before {
  content: ""; }

.fa-yelp:before {
  content: ""; }

.fa-newspaper-o:before {
  content: ""; }

.fa-wifi:before {
  content: ""; }

.fa-calculator:before {
  content: ""; }

.fa-paypal:before {
  content: ""; }

.fa-google-wallet:before {
  content: ""; }

.fa-cc-visa:before {
  content: ""; }

.fa-cc-mastercard:before {
  content: ""; }

.fa-cc-discover:before {
  content: ""; }

.fa-cc-amex:before {
  content: ""; }

.fa-cc-paypal:before {
  content: ""; }

.fa-cc-stripe:before {
  content: ""; }

.fa-bell-slash:before {
  content: ""; }

.fa-bell-slash-o:before {
  content: ""; }

.fa-trash:before {
  content: ""; }

.fa-copyright:before {
  content: ""; }

.fa-at:before {
  content: ""; }

.fa-eyedropper:before {
  content: ""; }

.fa-paint-brush:before {
  content: ""; }

.fa-birthday-cake:before {
  content: ""; }

.fa-area-chart:before {
  content: ""; }

.fa-pie-chart:before {
  content: ""; }

.fa-line-chart:before {
  content: ""; }

.fa-lastfm:before {
  content: ""; }

.fa-lastfm-square:before {
  content: ""; }

.fa-toggle-off:before {
  content: ""; }

.fa-toggle-on:before {
  content: ""; }

.fa-bicycle:before {
  content: ""; }

.fa-bus:before {
  content: ""; }

.fa-ioxhost:before {
  content: ""; }

.fa-angellist:before {
  content: ""; }

.fa-cc:before {
  content: ""; }

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

.fa-meanpath:before {
  content: ""; }

.fa-buysellads:before {
  content: ""; }

.fa-connectdevelop:before {
  content: ""; }

.fa-dashcube:before {
  content: ""; }

.fa-forumbee:before {
  content: ""; }

.fa-leanpub:before {
  content: ""; }

.fa-sellsy:before {
  content: ""; }

.fa-shirtsinbulk:before {
  content: ""; }

.fa-simplybuilt:before {
  content: ""; }

.fa-skyatlas:before {
  content: ""; }

.fa-cart-plus:before {
  content: ""; }

.fa-cart-arrow-down:before {
  content: ""; }

.fa-diamond:before {
  content: ""; }

.fa-ship:before {
  content: ""; }

.fa-user-secret:before {
  content: ""; }

.fa-motorcycle:before {
  content: ""; }

.fa-street-view:before {
  content: ""; }

.fa-heartbeat:before {
  content: ""; }

.fa-venus:before {
  content: ""; }

.fa-mars:before {
  content: ""; }

.fa-mercury:before {
  content: ""; }

.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

.fa-transgender-alt:before {
  content: ""; }

.fa-venus-double:before {
  content: ""; }

.fa-mars-double:before {
  content: ""; }

.fa-venus-mars:before {
  content: ""; }

.fa-mars-stroke:before {
  content: ""; }

.fa-mars-stroke-v:before {
  content: ""; }

.fa-mars-stroke-h:before {
  content: ""; }

.fa-neuter:before {
  content: ""; }

.fa-genderless:before {
  content: ""; }

.fa-facebook-official:before {
  content: ""; }

.fa-pinterest-p:before {
  content: ""; }

.fa-whatsapp:before {
  content: ""; }

.fa-server:before {
  content: ""; }

.fa-user-plus:before {
  content: ""; }

.fa-user-times:before {
  content: ""; }

.fa-hotel:before,
.fa-bed:before {
  content: ""; }

.fa-viacoin:before {
  content: ""; }

.fa-train:before {
  content: ""; }

.fa-subway:before {
  content: ""; }

.fa-medium:before {
  content: ""; }

.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

.fa-optin-monster:before {
  content: ""; }

.fa-opencart:before {
  content: ""; }

.fa-expeditedssl:before {
  content: ""; }

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: ""; }

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

.fa-mouse-pointer:before {
  content: ""; }

.fa-i-cursor:before {
  content: ""; }

.fa-object-group:before {
  content: ""; }

.fa-object-ungroup:before {
  content: ""; }

.fa-sticky-note:before {
  content: ""; }

.fa-sticky-note-o:before {
  content: ""; }

.fa-cc-jcb:before {
  content: ""; }

.fa-cc-diners-club:before {
  content: ""; }

.fa-clone:before {
  content: ""; }

.fa-balance-scale:before {
  content: ""; }

.fa-hourglass-o:before {
  content: ""; }

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

.fa-hourglass:before {
  content: ""; }

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

.fa-hand-scissors-o:before {
  content: ""; }

.fa-hand-lizard-o:before {
  content: ""; }

.fa-hand-spock-o:before {
  content: ""; }

.fa-hand-pointer-o:before {
  content: ""; }

.fa-hand-peace-o:before {
  content: ""; }

.fa-trademark:before {
  content: ""; }

.fa-registered:before {
  content: ""; }

.fa-creative-commons:before {
  content: ""; }

.fa-gg:before {
  content: ""; }

.fa-gg-circle:before {
  content: ""; }

.fa-tripadvisor:before {
  content: ""; }

.fa-odnoklassniki:before {
  content: ""; }

.fa-odnoklassniki-square:before {
  content: ""; }

.fa-get-pocket:before {
  content: ""; }

.fa-wikipedia-w:before {
  content: ""; }

.fa-safari:before {
  content: ""; }

.fa-chrome:before {
  content: ""; }

.fa-firefox:before {
  content: ""; }

.fa-opera:before {
  content: ""; }

.fa-internet-explorer:before {
  content: ""; }

.fa-tv:before,
.fa-television:before {
  content: ""; }

.fa-contao:before {
  content: ""; }

.fa-500px:before {
  content: ""; }

.fa-amazon:before {
  content: ""; }

.fa-calendar-plus-o:before {
  content: ""; }

.fa-calendar-minus-o:before {
  content: ""; }

.fa-calendar-times-o:before {
  content: ""; }

.fa-calendar-check-o:before {
  content: ""; }

.fa-industry:before {
  content: ""; }

.fa-map-pin:before {
  content: ""; }

.fa-map-signs:before {
  content: ""; }

.fa-map-o:before {
  content: ""; }

.fa-map:before {
  content: ""; }

.fa-commenting:before {
  content: ""; }

.fa-commenting-o:before {
  content: ""; }

.fa-houzz:before {
  content: ""; }

.fa-vimeo:before {
  content: ""; }

.fa-black-tie:before {
  content: ""; }

.fa-fonticons:before {
  content: ""; }

.fa-reddit-alien:before {
  content: ""; }

.fa-edge:before {
  content: ""; }

.fa-credit-card-alt:before {
  content: ""; }

.fa-codiepie:before {
  content: ""; }

.fa-modx:before {
  content: ""; }

.fa-fort-awesome:before {
  content: ""; }

.fa-usb:before {
  content: ""; }

.fa-product-hunt:before {
  content: ""; }

.fa-mixcloud:before {
  content: ""; }

.fa-scribd:before {
  content: ""; }

.fa-pause-circle:before {
  content: ""; }

.fa-pause-circle-o:before {
  content: ""; }

.fa-stop-circle:before {
  content: ""; }

.fa-stop-circle-o:before {
  content: ""; }

.fa-shopping-bag:before {
  content: ""; }

.fa-shopping-basket:before {
  content: ""; }

.fa-hashtag:before {
  content: ""; }

.fa-bluetooth:before {
  content: ""; }

.fa-bluetooth-b:before {
  content: ""; }

.fa-percent:before {
  content: ""; }

.fa-gitlab:before {
  content: ""; }

.fa-wpbeginner:before {
  content: ""; }

.fa-wpforms:before {
  content: ""; }

.fa-envira:before {
  content: ""; }

.fa-universal-access:before {
  content: ""; }

.fa-wheelchair-alt:before {
  content: ""; }

.fa-question-circle-o:before {
  content: ""; }

.fa-blind:before {
  content: ""; }

.fa-audio-description:before {
  content: ""; }

.fa-volume-control-phone:before {
  content: ""; }

.fa-braille:before {
  content: ""; }

.fa-assistive-listening-systems:before {
  content: ""; }

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: ""; }

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: ""; }

.fa-glide:before {
  content: ""; }

.fa-glide-g:before {
  content: ""; }

.fa-signing:before,
.fa-sign-language:before {
  content: ""; }

.fa-low-vision:before {
  content: ""; }

.fa-viadeo:before {
  content: ""; }

.fa-viadeo-square:before {
  content: ""; }

.fa-snapchat:before {
  content: ""; }

.fa-snapchat-ghost:before {
  content: ""; }

.fa-snapchat-square:before {
  content: ""; }

.fa-pied-piper:before {
  content: ""; }

.fa-first-order:before {
  content: ""; }

.fa-yoast:before {
  content: ""; }

.fa-themeisle:before {
  content: ""; }

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: ""; }

.fa-fa:before,
.fa-font-awesome:before {
  content: ""; }

.fa-handshake-o:before {
  content: ""; }

.fa-envelope-open:before {
  content: ""; }

.fa-envelope-open-o:before {
  content: ""; }

.fa-linode:before {
  content: ""; }

.fa-address-book:before {
  content: ""; }

.fa-address-book-o:before {
  content: ""; }

.fa-vcard:before,
.fa-address-card:before {
  content: ""; }

.fa-vcard-o:before,
.fa-address-card-o:before {
  content: ""; }

.fa-user-circle:before {
  content: ""; }

.fa-user-circle-o:before {
  content: ""; }

.fa-user-o:before {
  content: ""; }

.fa-id-badge:before {
  content: ""; }

.fa-drivers-license:before,
.fa-id-card:before {
  content: ""; }

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: ""; }

.fa-quora:before {
  content: ""; }

.fa-free-code-camp:before {
  content: ""; }

.fa-telegram:before {
  content: ""; }

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: ""; }

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: ""; }

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: ""; }

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: ""; }

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: ""; }

.fa-shower:before {
  content: ""; }

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: ""; }

.fa-podcast:before {
  content: ""; }

.fa-window-maximize:before {
  content: ""; }

.fa-window-minimize:before {
  content: ""; }

.fa-window-restore:before {
  content: ""; }

.fa-times-rectangle:before,
.fa-window-close:before {
  content: ""; }

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: ""; }

.fa-bandcamp:before {
  content: ""; }

.fa-grav:before {
  content: ""; }

.fa-etsy:before {
  content: ""; }

.fa-imdb:before {
  content: ""; }

.fa-ravelry:before {
  content: ""; }

.fa-eercast:before {
  content: ""; }

.fa-microchip:before {
  content: ""; }

.fa-snowflake-o:before {
  content: ""; }

.fa-superpowers:before {
  content: ""; }

.fa-wpexplorer:before {
  content: ""; }

.fa-meetup:before {
  content: ""; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

.fa-sm {
  font-size: 0.5em; }

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lato-v23-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/lato-v23-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v23-latin-regular.woff2") format("woff2"), url("../fonts/lato-v23-latin-regular.woff") format("woff"), url("../fonts/lato-v23-latin-regular.ttf") format("truetype"), url("../fonts/lato-v23-latin-regular.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lato-v23-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/lato-v23-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v23-latin-700.woff2") format("woff2"), url("../fonts/lato-v23-latin-700.woff") format("woff"), url("../fonts/lato-v23-latin-700.ttf") format("truetype"), url("../fonts/lato-v23-latin-700.svg#Lato") format("svg");
  /* Legacy iOS */ }

/* merriweather-sans-700 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/merriweather-sans-v22-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/merriweather-sans-v22-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-sans-v22-latin-700.woff2") format("woff2"), url("../fonts/merriweather-sans-v22-latin-700.woff") format("woff"), url("../fonts/merriweather-sans-v22-latin-700.ttf") format("truetype"), url("../fonts/merriweather-sans-v22-latin-700.svg#MerriweatherSans") format("svg");
  /* Legacy iOS */ }

/* merriweather-sans-800 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/merriweather-sans-v22-latin-800.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/merriweather-sans-v22-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-sans-v22-latin-800.woff2") format("woff2"), url("../fonts/merriweather-sans-v22-latin-800.woff") format("woff"), url("../fonts/merriweather-sans-v22-latin-800.ttf") format("truetype"), url("../fonts/merriweather-sans-v22-latin-800.svg#MerriweatherSans") format("svg");
  /* Legacy iOS */ }

.loading-spinner {
  display: block;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-right: -10px; }

body.loading-overlay::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 2001; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #dee2e6;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #dee2e6;
  border-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #dee2e6;
    border-radius: 2px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #dee2e6;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/**
 * We need a clone of bootstrap color-yiq mixin so we can get the same value for color
 */
.select2-container--bootstrap {
  display: block;
  /*------------------------------------*\
      #COMMON STYLES
  \*------------------------------------*/
  /**
   * Search field in the Select2 dropdown.
   */
  /**
   * No outline for all search fields - in the dropdown
   * and inline in multi Select2s.
   */
  /**
   * Adjust Select2's choices hover and selected styles to match
   * Bootstrap 4's default dropdown styles.
   *
   * @see https://getbootstrap.com/docs/4.0/components/dropdowns/
   */
  /**
   * Clear the selection.
   */
  /**
   * Address disabled Select2 styles.
   *
   * @see https://select2.github.io/examples.html#disabled
   * @see hhttps://getbootstrap.com/docs/4.0/components/forms/#disabled-forms
   */
  /*------------------------------------*\
      #DROPDOWN
  \*------------------------------------*/
  /**
   * Dropdown border color and box-shadow.
   */
  /**
   * Limit the dropdown height.
   */
  /*------------------------------------*\
      #SINGLE SELECT2
  \*------------------------------------*/
  /*------------------------------------*\
    #MULTIPLE SELECT2
  \*------------------------------------*/
  /**
   * Address Bootstrap control sizing classes
   *
   * 1. Reset Bootstrap defaults.
   * 2. Adjust the dropdown arrow button icon position.
   *
   * @see https://getbootstrap.com/docs/4.0/components/forms/#sizing
   */
  /* 1 */
  /*------------------------------------*\
    #RTL SUPPORT
  \*------------------------------------*/ }
  .select2-container--bootstrap .select2-selection {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #495057;
    font-size: 1rem;
    outline: 0; }
    @media (prefers-reduced-motion: reduce) {
      .select2-container--bootstrap .select2-selection {
        transition: none; } }
    .select2-container--bootstrap .select2-selection.form-control {
      border-radius: 2px; }
  .select2-container--bootstrap .select2-search--dropdown .select2-search__field {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #495057;
    font-size: 1rem; }
    @media (prefers-reduced-motion: reduce) {
      .select2-container--bootstrap .select2-search--dropdown .select2-search__field {
        transition: none; } }
  .select2-container--bootstrap .select2-search__field {
    outline: 0;
    /* Firefox 18- */
    /**
     * Firefox 19+
     *
     * @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
     */ }
    .select2-container--bootstrap .select2-search__field::-webkit-input-placeholder {
      color: #b4b4b4; }
    .select2-container--bootstrap .select2-search__field:-moz-placeholder {
      color: #b4b4b4; }
    .select2-container--bootstrap .select2-search__field::-moz-placeholder {
      color: #b4b4b4;
      opacity: 1; }
    .select2-container--bootstrap .select2-search__field:-ms-input-placeholder {
      color: #b4b4b4; }
  .select2-container--bootstrap .select2-results__option {
    padding: 0.375rem 0.75rem;
    /**
     * Disabled results.
     *
     * @see https://select2.github.io/examples.html#disabled-results
     */
    /**
     * Hover state.
     */
    /**
     * Selected state.
     */ }
    .select2-container--bootstrap .select2-results__option[role=group] {
      padding: 0; }
    .select2-container--bootstrap .select2-results__option[aria-disabled=true] {
      color: #6c757d;
      cursor: not-allowed; }
    .select2-container--bootstrap .select2-results__option[aria-selected=true] {
      background-color: #f8f9fa;
      color: #16181b; }
    .select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
      background-color: #ef476f;
      color: #fff; }
    .select2-container--bootstrap .select2-results__option .select2-results__option {
      padding: 0.375rem 0.75rem; }
      .select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__group {
        padding-left: 0; }
      .select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -0.75rem;
        padding-left: 1.5rem; }
        .select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -1.5rem;
          padding-left: 2.25rem; }
          .select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -2.25rem;
            padding-left: 3rem; }
            .select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
              margin-left: -3rem;
              padding-left: 3.75rem; }
              .select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
                margin-left: -3.75rem;
                padding-left: 4.5rem; }
  .select2-container--bootstrap .select2-results__group {
    color: #6c757d;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    white-space: nowrap; }
  .select2-container--bootstrap.select2-container--focus .select2-selection, .select2-container--bootstrap.select2-container--open .select2-selection {
    border-color: #f9bccb;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(239, 71, 111, 0.25); }
  .select2-container--bootstrap.select2-container--open {
    /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */
    /**
     * Handle border radii of the container when the dropdown is showing.
     */ }
    .select2-container--bootstrap.select2-container--open .select2-selection .select2-selection__arrow b {
      border-color: transparent transparent #b4b4b4 transparent;
      border-width: 0 0.25rem 0.25rem 0.25rem; }
    .select2-container--bootstrap.select2-container--open.select2-container--below .select2-selection {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-color: transparent;
      box-shadow: none; }
    .select2-container--bootstrap.select2-container--open.select2-container--above .select2-selection {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-color: transparent;
      box-shadow: none; }
  .select2-container--bootstrap .select2-selection__clear {
    color: #b4b4b4;
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
    .select2-container--bootstrap .select2-selection__clear:hover {
      color: "#111"; }
  .select2-container--bootstrap.select2-container--disabled .select2-selection {
    border-color: #ced4da;
    box-shadow: none; }
  .select2-container--bootstrap.select2-container--disabled .select2-selection,
  .select2-container--bootstrap.select2-container--disabled .select2-search__field {
    cursor: not-allowed; }
  .select2-container--bootstrap.select2-container--disabled .select2-selection,
  .select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
    background-color: #e9ecef; }
  .select2-container--bootstrap.select2-container--disabled .select2-selection__clear,
  .select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
    display: none; }
  .select2-container--bootstrap .select2-dropdown {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border-color: #f9bccb;
    overflow-x: hidden;
    margin-top: -1px; }
    .select2-container--bootstrap .select2-dropdown--above {
      box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.175);
      margin-top: 1px; }
  .select2-container--bootstrap .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto; }
  .select2-container--bootstrap .select2-selection--single {
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    padding: 0.375rem 1.5rem 0.375rem 0.75rem;
    /**
     * Adjust the single Select2's dropdown arrow button appearance.
     */ }
    .select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
      position: absolute;
      bottom: 0;
      right: 0.75rem;
      top: 0;
      width: 0.25rem; }
      .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
        border-color: #b4b4b4 transparent transparent transparent;
        border-style: solid;
        border-width: 0.25rem 0.25rem 0 0.25rem;
        height: 0;
        left: 0;
        margin-left: -0.25rem;
        margin-top: -0.125rem;
        position: absolute;
        top: 50%;
        width: 0; }
    .select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
      color: #495057;
      padding: 0; }
    .select2-container--bootstrap .select2-selection--single .select2-selection__placeholder {
      color: #b4b4b4; }
  .select2-container--bootstrap .select2-selection--multiple {
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0;
    height: auto;
    /**
     * Make Multi Select2's choices match Bootstrap 4's default button styles.
     */
    /**
     * Minus 2px borders.
     */
    /**
     * Clear the selection.
     */ }
    .select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
      box-sizing: border-box;
      display: block;
      line-height: 1.5;
      list-style: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container--bootstrap .select2-selection--multiple .select2-selection__placeholder {
      color: #b4b4b4;
      float: left;
      margin-top: 5px; }
    .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
      color: #495057;
      background: #e9ecef;
      border: 1px solid #007a9b;
      border-radius: 2px;
      cursor: default;
      float: left;
      margin: calc(0.375rem - 1px) 0 0 0.375rem;
      padding: 0 0.375rem; }
    .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
      background: transparent;
      padding: 0 0.75rem;
      height: calc(1.5em + 0.75rem + 2px);
      line-height: 1.5;
      margin: -1px 0;
      min-width: 5em; }
    .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
      color: #b4b4b4;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 0.1875rem; }
      .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
        color: "#111"; }
    .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
      margin-top: 0.375rem; }
  .select2-container--bootstrap .select2-selection--single.form-control-sm,
  .input-group-sm .select2-container--bootstrap .select2-selection--single,
  .form-group-sm .select2-container--bootstrap .select2-selection--single {
    border-radius: 2px;
    font-size: 0.875rem;
    height: calc(1.5em + 0.5rem + 2px);
    line-height: 1.5;
    padding: 0.25rem 1.25rem 0.25rem 0.5rem;
    /* 2 */ }
    .select2-container--bootstrap .select2-selection--single.form-control-sm .select2-selection__arrow b,
    .input-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
    .form-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
      margin-left: -0.25rem; }
  .select2-container--bootstrap .select2-selection--multiple.form-control-sm,
  .input-group-sm .select2-container--bootstrap .select2-selection--multiple,
  .form-group-sm .select2-container--bootstrap .select2-selection--multiple {
    border-radius: 2px;
    min-height: calc(1.5em + 0.5rem + 2px); }
    .select2-container--bootstrap .select2-selection--multiple.form-control-sm .select2-selection__choice,
    .input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
    .form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
      font-size: 0.875rem;
      line-height: 1.5;
      margin: calc(0.25rem - 1px) 0 0 0.25rem;
      padding: 0 0.25rem; }
    .select2-container--bootstrap .select2-selection--multiple.form-control-sm .select2-search--inline .select2-search__field,
    .input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
    .form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
      padding: 0 0.5rem;
      font-size: 0.875rem;
      height: calc(1.5em + 0.5rem + 2px);
      line-height: 1.5; }
    .select2-container--bootstrap .select2-selection--multiple.form-control-sm .select2-selection__clear,
    .input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
    .form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
      margin-top: 0.25rem; }
  .select2-container--bootstrap .select2-selection--single.form-control-lg,
  .input-group-lg .select2-container--bootstrap .select2-selection--single,
  .form-group-lg .select2-container--bootstrap .select2-selection--single {
    border-radius: 2px;
    font-size: 1.25rem;
    height: calc(1.5em + 1rem + 2px);
    line-height: 1.5;
    padding: 0.5rem 1.9375rem 0.5rem 1rem;
    /* 1 */ }
    .select2-container--bootstrap .select2-selection--single.form-control-lg .select2-selection__arrow,
    .input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow,
    .form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
      width: 0.3125rem; }
      .select2-container--bootstrap .select2-selection--single.form-control-lg .select2-selection__arrow b,
      .input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
      .form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
        border-width: 0.3125rem 0.3125rem 0 0.3125rem;
        margin-left: -0.3125rem;
        margin-left: -0.5rem;
        margin-top: -0.15625rem; }
  .select2-container--bootstrap .select2-selection--multiple.form-control-lg,
  .input-group-lg .select2-container--bootstrap .select2-selection--multiple,
  .form-group-lg .select2-container--bootstrap .select2-selection--multiple {
    min-height: calc(1.5em + 1rem + 2px);
    border-radius: 2px; }
    .select2-container--bootstrap .select2-selection--multiple.form-control-lg .select2-selection__choice,
    .input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
    .form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 2px;
      margin: calc(0.5rem - 1px) 0 0 0.5rem;
      padding: 0 0.5rem; }
    .select2-container--bootstrap .select2-selection--multiple.form-control-lg .select2-search--inline .select2-search__field,
    .input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
    .form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
      padding: 0 1rem;
      font-size: 1.25rem;
      height: calc(1.5em + 1rem + 2px);
      line-height: 1.5; }
    .select2-container--bootstrap .select2-selection--multiple.form-control-lg .select2-selection__clear,
    .input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
    .form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
      margin-top: 0.5rem; }
  .select2-container--bootstrap .select2-selection.form-control-lg.select2-container--open .select2-selection--single {
    /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */ }
    .select2-container--bootstrap .select2-selection.form-control-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #b4b4b4 transparent;
      border-width: 0 0.3125rem 0.3125rem 0.3125rem; }
  .input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single {
    /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */ }
    .input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #b4b4b4 transparent;
      border-width: 0 0.3125rem 0.3125rem 0.3125rem; }
  .select2-container--bootstrap[dir="rtl"] {
    /**
     * Single Select2
     *
     * 1. Makes sure that .select2-selection__placeholder is positioned
     *    correctly.
     */
    /**
     * Multiple Select2
     */ }
    .select2-container--bootstrap[dir="rtl"] .select2-selection--single {
      padding-left: 1.5rem;
      padding-right: 0.75rem; }
      .select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__rendered {
        padding-right: 0;
        padding-left: 0;
        text-align: right;
        /* 1 */ }
      .select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__clear {
        float: left; }
      .select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__arrow {
        left: 0.75rem;
        right: auto; }
        .select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__arrow b {
          margin-left: 0; }
    .select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
    .select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
    .select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-search--inline {
      float: right; }
    .select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
      margin-left: 0;
      margin-right: 0.375rem; }
    .select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto; }
  .select2-container--bootstrap .select2-dropdown[dir="rtl"] .select2-results__options {
    text-align: right; }

/*------------------------------------*\
  #ADDITIONAL GOODIES
\*------------------------------------*/
/**
 * Address Bootstrap's validation states
 *
 * If a Select2 widget parent has one of Bootstrap's validation state modifier
 * classes, adjust Select2's border colors and focus states accordingly.
 * You may apply said classes to the Select2 dropdown (body > .select2-container)
 * via JavaScript match Bootstraps' to make its styles match.
 *
 * @see https://getbootstrap.com/docs/4.0/components/forms/#validation
 */
.is-valid .select2-dropdown,
.is-valid .select2-selection {
  border-color: #28a745; }

.is-valid .select2-container--focus .select2-selection,
.is-valid .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #5dd879;
  border-color: #1e7e34; }
  .is-valid .select2-container--focus .select2-selection:focus,
  .is-valid .select2-container--open .select2-selection:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.is-valid.select2-drop-active {
  border-color: #1e7e34; }
  .is-valid.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #1e7e34; }

.is-invalid .select2-dropdown,
.is-invalid .select2-selection {
  border-color: #dc3545; }

.is-invalid .select2-container--focus .select2-selection,
.is-invalid .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eb8c95;
  border-color: #bd2130; }
  .is-invalid .select2-container--focus .select2-selection:focus,
  .is-invalid .select2-container--open .select2-selection:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.is-invalid.select2-drop-active {
  border-color: #bd2130; }
  .is-invalid.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #bd2130; }

/* Validation classes on parent element. Preserved Bootstrap 3 validation classes */
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #ffc107; }

.has-warning .select2-container--focus .select2-selection,
.has-warning .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffdb6d;
  border-color: #d39e00; }
  .has-warning .select2-container--focus .select2-selection:focus,
  .has-warning .select2-container--open .select2-selection:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }

.has-warning.select2-drop-active {
  border-color: #d39e00; }
  .has-warning.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #d39e00; }

.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #dc3545; }

.has-error .select2-container--focus .select2-selection,
.has-error .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eb8c95;
  border-color: #bd2130; }
  .has-error .select2-container--focus .select2-selection:focus,
  .has-error .select2-container--open .select2-selection:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.has-error.select2-drop-active {
  border-color: #bd2130; }
  .has-error.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #bd2130; }

.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #28a745; }

.has-success .select2-container--focus .select2-selection,
.has-success .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #5dd879;
  border-color: #1e7e34; }
  .has-success .select2-container--focus .select2-selection:focus,
  .has-success .select2-container--open .select2-selection:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.has-success.select2-drop-active {
  border-color: #1e7e34; }
  .has-success.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #1e7e34; }

/**
 * Select2 widgets in Bootstrap Input Groups
 *
 * @see https://getbootstrap.com/docs/4.0/components/input-group/
 * @see https://github.com/twbs/bootstrap/blob/v4.0.0-beta.2/scss/_input-group.scss
 */
/**
 * Reset rounded corners
 */
.input-group > .select2-hidden-accessible:first-child + .select2-container--bootstrap > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:first-child + .select2-container--bootstrap > .selection > .select2-selection.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection.form-control {
  border-radius: 0; }

.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container--bootstrap:last-child > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container--bootstrap:last-child > .selection > .select2-selection.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .select2-container--bootstrap {
  flex: 1 1 auto;
  position: relative;
  z-index: 2;
  width: 1%;
  margin-bottom: 0;
  /**
   * Adjust z-index like Bootstrap does to show the focus-box-shadow
   * above appended buttons in .input-group and .form-group.
   */
  /**
   * Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
   * Multi Select2's height which - depending on how many elements have been selected -
   * may grow taller than its initial size.
   *
   * @see https://github.com/twbs/bootstrap/blob/v4.0.0-beta.2/scss/_input-group.scss
   */ }
  .input-group > .select2-container--bootstrap > .selection {
    display: flex;
    flex: 1 1 auto; }
    .input-group > .select2-container--bootstrap > .selection > .select2-selection.form-control {
      float: none; }
  .input-group > .select2-container--bootstrap.select2-container--open, .input-group > .select2-container--bootstrap.select2-container--focus {
    z-index: 3; }
  .input-group > .select2-container--bootstrap,
  .input-group > .select2-container--bootstrap .input-group-append,
  .input-group > .select2-container--bootstrap .input-group-prepend,
  .input-group > .select2-container--bootstrap .input-group-append .btn,
  .input-group > .select2-container--bootstrap .input-group-prepend .btn {
    vertical-align: top; }

/**
 * Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
 *
 * Provides `!important` for certain properties of the class applied to the
 * original `<select>` element to hide it.
 *
 * @see https://github.com/select2/select2/pull/3301
 * @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
 */
.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important; }

/**
 * Display override for inline forms
 */
@media (min-width: 576px) {
  .form-inline .select2-container--bootstrap {
    display: inline-block; } }

.btn-xs {
  padding: 1px 2px;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.1rem; }

.widget-admin-btn-bar {
  margin-top: 1rem; }

.widget-container, .widget-page {
  border: 0 solid transparent; }

.widget-container > .card-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0; }

.widget-page {
  margin-top: 1.5rem; }

.widget-container {
  margin-bottom: 1.2rem; }

.widget-placeholder--editing {
  margin-top: 1.2rem;
  border: 2px dashed #007a9b;
  border-radius: 5px; }

.widget-edit-bar {
  text-align: right;
  opacity: .5;
  position: relative;
  top: -4px; }

.widget-icons-top {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1000;
  opacity: 0.5; }

.widget-nav-bar {
  margin-top: 1.2rem;
  margin-bottom: 2rem; }

.opacity-50 {
  opacity: .5; }

.widget-content-editing {
  min-height: 100px;
  padding-top: 2.3rem;
  background-color: #f6f6f6;
  text-align: center; }

.ideafactory-info .card .card {
  margin-bottom: 1.8rem; }

.ideafactory-info .card .card .card-header {
  background-color: #007a9b;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem; }

.ideafactory-info .card .card .card-header h2,
.ideafactory-info .card .card .card-header h2 a {
  color: white; }

.ideafactory-info .card .card .card-header h2 {
  margin-bottom: 0; }

.ideafactory-info .card .card .card-body {
  background-color: #ebebeb; }

.ideafactory-info .card .card .card {
  margin-bottom: 0; }

.ideafactory-info .card .card .card .card-header {
  background-color: #ebebeb;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }

.ideafactory-info .card .card .card .card-header h2,
.ideafactory-info .card .card .card .card-header h2 a {
  color: #007a9b;
  font-family: "Lato", sans-serif;
  text-align: left; }

.ideafactory-info .card .card .card .card-header h2 {
  padding-top: .2rem;
  padding-bottom: 0rem; }

.ideafactory-info .card .card .card .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }

.row--sortable:before {
  content: ' ';
  display: block;
  height: 1rem;
  width: 100%; }

.row--sortable:after {
  content: ' ';
  display: block;
  height: 1rem;
  width: 100%; }

/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 * http://circle.firchow.net/
 *
*****************************************************************/
.rect-auto, .c100.p51 .slice, .c100.p52 .slice, .c100.p53 .slice, .c100.p54 .slice, .c100.p55 .slice, .c100.p56 .slice, .c100.p57 .slice, .c100.p58 .slice, .c100.p59 .slice, .c100.p60 .slice, .c100.p61 .slice, .c100.p62 .slice, .c100.p63 .slice, .c100.p64 .slice, .c100.p65 .slice, .c100.p66 .slice, .c100.p67 .slice, .c100.p68 .slice, .c100.p69 .slice, .c100.p70 .slice, .c100.p71 .slice, .c100.p72 .slice, .c100.p73 .slice, .c100.p74 .slice, .c100.p75 .slice, .c100.p76 .slice, .c100.p77 .slice, .c100.p78 .slice, .c100.p79 .slice, .c100.p80 .slice, .c100.p81 .slice, .c100.p82 .slice, .c100.p83 .slice, .c100.p84 .slice, .c100.p85 .slice, .c100.p86 .slice, .c100.p87 .slice, .c100.p88 .slice, .c100.p89 .slice, .c100.p90 .slice, .c100.p91 .slice, .c100.p92 .slice, .c100.p93 .slice, .c100.p94 .slice, .c100.p95 .slice, .c100.p96 .slice, .c100.p97 .slice, .c100.p98 .slice, .c100.p99 .slice, .c100.p100 .slice {
  clip: rect(auto, auto, auto, auto); }

.pie, .c100 .bar, .c100.p51 .fill, .c100.p52 .fill, .c100.p53 .fill, .c100.p54 .fill, .c100.p55 .fill, .c100.p56 .fill, .c100.p57 .fill, .c100.p58 .fill, .c100.p59 .fill, .c100.p60 .fill, .c100.p61 .fill, .c100.p62 .fill, .c100.p63 .fill, .c100.p64 .fill, .c100.p65 .fill, .c100.p66 .fill, .c100.p67 .fill, .c100.p68 .fill, .c100.p69 .fill, .c100.p70 .fill, .c100.p71 .fill, .c100.p72 .fill, .c100.p73 .fill, .c100.p74 .fill, .c100.p75 .fill, .c100.p76 .fill, .c100.p77 .fill, .c100.p78 .fill, .c100.p79 .fill, .c100.p80 .fill, .c100.p81 .fill, .c100.p82 .fill, .c100.p83 .fill, .c100.p84 .fill, .c100.p85 .fill, .c100.p86 .fill, .c100.p87 .fill, .c100.p88 .fill, .c100.p89 .fill, .c100.p90 .fill, .c100.p91 .fill, .c100.p92 .fill, .c100.p93 .fill, .c100.p94 .fill, .c100.p95 .fill, .c100.p96 .fill, .c100.p97 .fill, .c100.p98 .fill, .c100.p99 .fill, .c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #ffdc00;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg); }

.pie-fill, .c100.p51 .bar:after, .c100.p51 .fill, .c100.p52 .bar:after, .c100.p52 .fill, .c100.p53 .bar:after, .c100.p53 .fill, .c100.p54 .bar:after, .c100.p54 .fill, .c100.p55 .bar:after, .c100.p55 .fill, .c100.p56 .bar:after, .c100.p56 .fill, .c100.p57 .bar:after, .c100.p57 .fill, .c100.p58 .bar:after, .c100.p58 .fill, .c100.p59 .bar:after, .c100.p59 .fill, .c100.p60 .bar:after, .c100.p60 .fill, .c100.p61 .bar:after, .c100.p61 .fill, .c100.p62 .bar:after, .c100.p62 .fill, .c100.p63 .bar:after, .c100.p63 .fill, .c100.p64 .bar:after, .c100.p64 .fill, .c100.p65 .bar:after, .c100.p65 .fill, .c100.p66 .bar:after, .c100.p66 .fill, .c100.p67 .bar:after, .c100.p67 .fill, .c100.p68 .bar:after, .c100.p68 .fill, .c100.p69 .bar:after, .c100.p69 .fill, .c100.p70 .bar:after, .c100.p70 .fill, .c100.p71 .bar:after, .c100.p71 .fill, .c100.p72 .bar:after, .c100.p72 .fill, .c100.p73 .bar:after, .c100.p73 .fill, .c100.p74 .bar:after, .c100.p74 .fill, .c100.p75 .bar:after, .c100.p75 .fill, .c100.p76 .bar:after, .c100.p76 .fill, .c100.p77 .bar:after, .c100.p77 .fill, .c100.p78 .bar:after, .c100.p78 .fill, .c100.p79 .bar:after, .c100.p79 .fill, .c100.p80 .bar:after, .c100.p80 .fill, .c100.p81 .bar:after, .c100.p81 .fill, .c100.p82 .bar:after, .c100.p82 .fill, .c100.p83 .bar:after, .c100.p83 .fill, .c100.p84 .bar:after, .c100.p84 .fill, .c100.p85 .bar:after, .c100.p85 .fill, .c100.p86 .bar:after, .c100.p86 .fill, .c100.p87 .bar:after, .c100.p87 .fill, .c100.p88 .bar:after, .c100.p88 .fill, .c100.p89 .bar:after, .c100.p89 .fill, .c100.p90 .bar:after, .c100.p90 .fill, .c100.p91 .bar:after, .c100.p91 .fill, .c100.p92 .bar:after, .c100.p92 .fill, .c100.p93 .bar:after, .c100.p93 .fill, .c100.p94 .bar:after, .c100.p94 .fill, .c100.p95 .bar:after, .c100.p95 .fill, .c100.p96 .bar:after, .c100.p96 .fill, .c100.p97 .bar:after, .c100.p97 .fill, .c100.p98 .bar:after, .c100.p98 .fill, .c100.p99 .bar:after, .c100.p99 .fill, .c100.p100 .bar:after, .c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #ccc; }
  .c100 *, .c100 *:before, .c100 *:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }
  .c100.center {
    float: none;
    margin: 0 auto; }
  .c100.big {
    font-size: 240px; }
  .c100.small {
    font-size: 80px; }
  .c100 > span {
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    width: 5em;
    line-height: 5em;
    font-size: 0.2em;
    color: #ccc;
    display: block;
    text-align: center;
    white-space: nowrap;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  .c100:after {
    position: absolute;
    top: 0.08em;
    left: 0.08em;
    display: block;
    content: " ";
    border-radius: 50%;
    background-color: #FFFFFF;
    width: 0.84em;
    height: 0.84em;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  .c100 .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em); }
  .c100.p1 .bar {
    -webkit-transform: rotate(3.6deg);
    -moz-transform: rotate(3.6deg);
    -ms-transform: rotate(3.6deg);
    -o-transform: rotate(3.6deg);
    transform: rotate(3.6deg); }
  .c100.p2 .bar {
    -webkit-transform: rotate(7.2deg);
    -moz-transform: rotate(7.2deg);
    -ms-transform: rotate(7.2deg);
    -o-transform: rotate(7.2deg);
    transform: rotate(7.2deg); }
  .c100.p3 .bar {
    -webkit-transform: rotate(10.8deg);
    -moz-transform: rotate(10.8deg);
    -ms-transform: rotate(10.8deg);
    -o-transform: rotate(10.8deg);
    transform: rotate(10.8deg); }
  .c100.p4 .bar {
    -webkit-transform: rotate(14.4deg);
    -moz-transform: rotate(14.4deg);
    -ms-transform: rotate(14.4deg);
    -o-transform: rotate(14.4deg);
    transform: rotate(14.4deg); }
  .c100.p5 .bar {
    -webkit-transform: rotate(18deg);
    -moz-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    -o-transform: rotate(18deg);
    transform: rotate(18deg); }
  .c100.p6 .bar {
    -webkit-transform: rotate(21.6deg);
    -moz-transform: rotate(21.6deg);
    -ms-transform: rotate(21.6deg);
    -o-transform: rotate(21.6deg);
    transform: rotate(21.6deg); }
  .c100.p7 .bar {
    -webkit-transform: rotate(25.2deg);
    -moz-transform: rotate(25.2deg);
    -ms-transform: rotate(25.2deg);
    -o-transform: rotate(25.2deg);
    transform: rotate(25.2deg); }
  .c100.p8 .bar {
    -webkit-transform: rotate(28.8deg);
    -moz-transform: rotate(28.8deg);
    -ms-transform: rotate(28.8deg);
    -o-transform: rotate(28.8deg);
    transform: rotate(28.8deg); }
  .c100.p9 .bar {
    -webkit-transform: rotate(32.4deg);
    -moz-transform: rotate(32.4deg);
    -ms-transform: rotate(32.4deg);
    -o-transform: rotate(32.4deg);
    transform: rotate(32.4deg); }
  .c100.p10 .bar {
    -webkit-transform: rotate(36deg);
    -moz-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    -o-transform: rotate(36deg);
    transform: rotate(36deg); }
  .c100.p11 .bar {
    -webkit-transform: rotate(39.6deg);
    -moz-transform: rotate(39.6deg);
    -ms-transform: rotate(39.6deg);
    -o-transform: rotate(39.6deg);
    transform: rotate(39.6deg); }
  .c100.p12 .bar {
    -webkit-transform: rotate(43.2deg);
    -moz-transform: rotate(43.2deg);
    -ms-transform: rotate(43.2deg);
    -o-transform: rotate(43.2deg);
    transform: rotate(43.2deg); }
  .c100.p13 .bar {
    -webkit-transform: rotate(46.8deg);
    -moz-transform: rotate(46.8deg);
    -ms-transform: rotate(46.8deg);
    -o-transform: rotate(46.8deg);
    transform: rotate(46.8deg); }
  .c100.p14 .bar {
    -webkit-transform: rotate(50.4deg);
    -moz-transform: rotate(50.4deg);
    -ms-transform: rotate(50.4deg);
    -o-transform: rotate(50.4deg);
    transform: rotate(50.4deg); }
  .c100.p15 .bar {
    -webkit-transform: rotate(54deg);
    -moz-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    -o-transform: rotate(54deg);
    transform: rotate(54deg); }
  .c100.p16 .bar {
    -webkit-transform: rotate(57.6deg);
    -moz-transform: rotate(57.6deg);
    -ms-transform: rotate(57.6deg);
    -o-transform: rotate(57.6deg);
    transform: rotate(57.6deg); }
  .c100.p17 .bar {
    -webkit-transform: rotate(61.2deg);
    -moz-transform: rotate(61.2deg);
    -ms-transform: rotate(61.2deg);
    -o-transform: rotate(61.2deg);
    transform: rotate(61.2deg); }
  .c100.p18 .bar {
    -webkit-transform: rotate(64.8deg);
    -moz-transform: rotate(64.8deg);
    -ms-transform: rotate(64.8deg);
    -o-transform: rotate(64.8deg);
    transform: rotate(64.8deg); }
  .c100.p19 .bar {
    -webkit-transform: rotate(68.4deg);
    -moz-transform: rotate(68.4deg);
    -ms-transform: rotate(68.4deg);
    -o-transform: rotate(68.4deg);
    transform: rotate(68.4deg); }
  .c100.p20 .bar {
    -webkit-transform: rotate(72deg);
    -moz-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    -o-transform: rotate(72deg);
    transform: rotate(72deg); }
  .c100.p21 .bar {
    -webkit-transform: rotate(75.6deg);
    -moz-transform: rotate(75.6deg);
    -ms-transform: rotate(75.6deg);
    -o-transform: rotate(75.6deg);
    transform: rotate(75.6deg); }
  .c100.p22 .bar {
    -webkit-transform: rotate(79.2deg);
    -moz-transform: rotate(79.2deg);
    -ms-transform: rotate(79.2deg);
    -o-transform: rotate(79.2deg);
    transform: rotate(79.2deg); }
  .c100.p23 .bar {
    -webkit-transform: rotate(82.8deg);
    -moz-transform: rotate(82.8deg);
    -ms-transform: rotate(82.8deg);
    -o-transform: rotate(82.8deg);
    transform: rotate(82.8deg); }
  .c100.p24 .bar {
    -webkit-transform: rotate(86.4deg);
    -moz-transform: rotate(86.4deg);
    -ms-transform: rotate(86.4deg);
    -o-transform: rotate(86.4deg);
    transform: rotate(86.4deg); }
  .c100.p25 .bar {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  .c100.p26 .bar {
    -webkit-transform: rotate(93.6deg);
    -moz-transform: rotate(93.6deg);
    -ms-transform: rotate(93.6deg);
    -o-transform: rotate(93.6deg);
    transform: rotate(93.6deg); }
  .c100.p27 .bar {
    -webkit-transform: rotate(97.2deg);
    -moz-transform: rotate(97.2deg);
    -ms-transform: rotate(97.2deg);
    -o-transform: rotate(97.2deg);
    transform: rotate(97.2deg); }
  .c100.p28 .bar {
    -webkit-transform: rotate(100.8deg);
    -moz-transform: rotate(100.8deg);
    -ms-transform: rotate(100.8deg);
    -o-transform: rotate(100.8deg);
    transform: rotate(100.8deg); }
  .c100.p29 .bar {
    -webkit-transform: rotate(104.4deg);
    -moz-transform: rotate(104.4deg);
    -ms-transform: rotate(104.4deg);
    -o-transform: rotate(104.4deg);
    transform: rotate(104.4deg); }
  .c100.p30 .bar {
    -webkit-transform: rotate(108deg);
    -moz-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    -o-transform: rotate(108deg);
    transform: rotate(108deg); }
  .c100.p31 .bar {
    -webkit-transform: rotate(111.6deg);
    -moz-transform: rotate(111.6deg);
    -ms-transform: rotate(111.6deg);
    -o-transform: rotate(111.6deg);
    transform: rotate(111.6deg); }
  .c100.p32 .bar {
    -webkit-transform: rotate(115.2deg);
    -moz-transform: rotate(115.2deg);
    -ms-transform: rotate(115.2deg);
    -o-transform: rotate(115.2deg);
    transform: rotate(115.2deg); }
  .c100.p33 .bar {
    -webkit-transform: rotate(118.8deg);
    -moz-transform: rotate(118.8deg);
    -ms-transform: rotate(118.8deg);
    -o-transform: rotate(118.8deg);
    transform: rotate(118.8deg); }
  .c100.p34 .bar {
    -webkit-transform: rotate(122.4deg);
    -moz-transform: rotate(122.4deg);
    -ms-transform: rotate(122.4deg);
    -o-transform: rotate(122.4deg);
    transform: rotate(122.4deg); }
  .c100.p35 .bar {
    -webkit-transform: rotate(126deg);
    -moz-transform: rotate(126deg);
    -ms-transform: rotate(126deg);
    -o-transform: rotate(126deg);
    transform: rotate(126deg); }
  .c100.p36 .bar {
    -webkit-transform: rotate(129.6deg);
    -moz-transform: rotate(129.6deg);
    -ms-transform: rotate(129.6deg);
    -o-transform: rotate(129.6deg);
    transform: rotate(129.6deg); }
  .c100.p37 .bar {
    -webkit-transform: rotate(133.2deg);
    -moz-transform: rotate(133.2deg);
    -ms-transform: rotate(133.2deg);
    -o-transform: rotate(133.2deg);
    transform: rotate(133.2deg); }
  .c100.p38 .bar {
    -webkit-transform: rotate(136.8deg);
    -moz-transform: rotate(136.8deg);
    -ms-transform: rotate(136.8deg);
    -o-transform: rotate(136.8deg);
    transform: rotate(136.8deg); }
  .c100.p39 .bar {
    -webkit-transform: rotate(140.4deg);
    -moz-transform: rotate(140.4deg);
    -ms-transform: rotate(140.4deg);
    -o-transform: rotate(140.4deg);
    transform: rotate(140.4deg); }
  .c100.p40 .bar {
    -webkit-transform: rotate(144deg);
    -moz-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    -o-transform: rotate(144deg);
    transform: rotate(144deg); }
  .c100.p41 .bar {
    -webkit-transform: rotate(147.6deg);
    -moz-transform: rotate(147.6deg);
    -ms-transform: rotate(147.6deg);
    -o-transform: rotate(147.6deg);
    transform: rotate(147.6deg); }
  .c100.p42 .bar {
    -webkit-transform: rotate(151.2deg);
    -moz-transform: rotate(151.2deg);
    -ms-transform: rotate(151.2deg);
    -o-transform: rotate(151.2deg);
    transform: rotate(151.2deg); }
  .c100.p43 .bar {
    -webkit-transform: rotate(154.8deg);
    -moz-transform: rotate(154.8deg);
    -ms-transform: rotate(154.8deg);
    -o-transform: rotate(154.8deg);
    transform: rotate(154.8deg); }
  .c100.p44 .bar {
    -webkit-transform: rotate(158.4deg);
    -moz-transform: rotate(158.4deg);
    -ms-transform: rotate(158.4deg);
    -o-transform: rotate(158.4deg);
    transform: rotate(158.4deg); }
  .c100.p45 .bar {
    -webkit-transform: rotate(162deg);
    -moz-transform: rotate(162deg);
    -ms-transform: rotate(162deg);
    -o-transform: rotate(162deg);
    transform: rotate(162deg); }
  .c100.p46 .bar {
    -webkit-transform: rotate(165.6deg);
    -moz-transform: rotate(165.6deg);
    -ms-transform: rotate(165.6deg);
    -o-transform: rotate(165.6deg);
    transform: rotate(165.6deg); }
  .c100.p47 .bar {
    -webkit-transform: rotate(169.2deg);
    -moz-transform: rotate(169.2deg);
    -ms-transform: rotate(169.2deg);
    -o-transform: rotate(169.2deg);
    transform: rotate(169.2deg); }
  .c100.p48 .bar {
    -webkit-transform: rotate(172.8deg);
    -moz-transform: rotate(172.8deg);
    -ms-transform: rotate(172.8deg);
    -o-transform: rotate(172.8deg);
    transform: rotate(172.8deg); }
  .c100.p49 .bar {
    -webkit-transform: rotate(176.4deg);
    -moz-transform: rotate(176.4deg);
    -ms-transform: rotate(176.4deg);
    -o-transform: rotate(176.4deg);
    transform: rotate(176.4deg); }
  .c100.p50 .bar {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  .c100.p51 .bar {
    -webkit-transform: rotate(183.6deg);
    -moz-transform: rotate(183.6deg);
    -ms-transform: rotate(183.6deg);
    -o-transform: rotate(183.6deg);
    transform: rotate(183.6deg); }
  .c100.p52 .bar {
    -webkit-transform: rotate(187.2deg);
    -moz-transform: rotate(187.2deg);
    -ms-transform: rotate(187.2deg);
    -o-transform: rotate(187.2deg);
    transform: rotate(187.2deg); }
  .c100.p53 .bar {
    -webkit-transform: rotate(190.8deg);
    -moz-transform: rotate(190.8deg);
    -ms-transform: rotate(190.8deg);
    -o-transform: rotate(190.8deg);
    transform: rotate(190.8deg); }
  .c100.p54 .bar {
    -webkit-transform: rotate(194.4deg);
    -moz-transform: rotate(194.4deg);
    -ms-transform: rotate(194.4deg);
    -o-transform: rotate(194.4deg);
    transform: rotate(194.4deg); }
  .c100.p55 .bar {
    -webkit-transform: rotate(198deg);
    -moz-transform: rotate(198deg);
    -ms-transform: rotate(198deg);
    -o-transform: rotate(198deg);
    transform: rotate(198deg); }
  .c100.p56 .bar {
    -webkit-transform: rotate(201.6deg);
    -moz-transform: rotate(201.6deg);
    -ms-transform: rotate(201.6deg);
    -o-transform: rotate(201.6deg);
    transform: rotate(201.6deg); }
  .c100.p57 .bar {
    -webkit-transform: rotate(205.2deg);
    -moz-transform: rotate(205.2deg);
    -ms-transform: rotate(205.2deg);
    -o-transform: rotate(205.2deg);
    transform: rotate(205.2deg); }
  .c100.p58 .bar {
    -webkit-transform: rotate(208.8deg);
    -moz-transform: rotate(208.8deg);
    -ms-transform: rotate(208.8deg);
    -o-transform: rotate(208.8deg);
    transform: rotate(208.8deg); }
  .c100.p59 .bar {
    -webkit-transform: rotate(212.4deg);
    -moz-transform: rotate(212.4deg);
    -ms-transform: rotate(212.4deg);
    -o-transform: rotate(212.4deg);
    transform: rotate(212.4deg); }
  .c100.p60 .bar {
    -webkit-transform: rotate(216deg);
    -moz-transform: rotate(216deg);
    -ms-transform: rotate(216deg);
    -o-transform: rotate(216deg);
    transform: rotate(216deg); }
  .c100.p61 .bar {
    -webkit-transform: rotate(219.6deg);
    -moz-transform: rotate(219.6deg);
    -ms-transform: rotate(219.6deg);
    -o-transform: rotate(219.6deg);
    transform: rotate(219.6deg); }
  .c100.p62 .bar {
    -webkit-transform: rotate(223.2deg);
    -moz-transform: rotate(223.2deg);
    -ms-transform: rotate(223.2deg);
    -o-transform: rotate(223.2deg);
    transform: rotate(223.2deg); }
  .c100.p63 .bar {
    -webkit-transform: rotate(226.8deg);
    -moz-transform: rotate(226.8deg);
    -ms-transform: rotate(226.8deg);
    -o-transform: rotate(226.8deg);
    transform: rotate(226.8deg); }
  .c100.p64 .bar {
    -webkit-transform: rotate(230.4deg);
    -moz-transform: rotate(230.4deg);
    -ms-transform: rotate(230.4deg);
    -o-transform: rotate(230.4deg);
    transform: rotate(230.4deg); }
  .c100.p65 .bar {
    -webkit-transform: rotate(234deg);
    -moz-transform: rotate(234deg);
    -ms-transform: rotate(234deg);
    -o-transform: rotate(234deg);
    transform: rotate(234deg); }
  .c100.p66 .bar {
    -webkit-transform: rotate(237.6deg);
    -moz-transform: rotate(237.6deg);
    -ms-transform: rotate(237.6deg);
    -o-transform: rotate(237.6deg);
    transform: rotate(237.6deg); }
  .c100.p67 .bar {
    -webkit-transform: rotate(241.2deg);
    -moz-transform: rotate(241.2deg);
    -ms-transform: rotate(241.2deg);
    -o-transform: rotate(241.2deg);
    transform: rotate(241.2deg); }
  .c100.p68 .bar {
    -webkit-transform: rotate(244.8deg);
    -moz-transform: rotate(244.8deg);
    -ms-transform: rotate(244.8deg);
    -o-transform: rotate(244.8deg);
    transform: rotate(244.8deg); }
  .c100.p69 .bar {
    -webkit-transform: rotate(248.4deg);
    -moz-transform: rotate(248.4deg);
    -ms-transform: rotate(248.4deg);
    -o-transform: rotate(248.4deg);
    transform: rotate(248.4deg); }
  .c100.p70 .bar {
    -webkit-transform: rotate(252deg);
    -moz-transform: rotate(252deg);
    -ms-transform: rotate(252deg);
    -o-transform: rotate(252deg);
    transform: rotate(252deg); }
  .c100.p71 .bar {
    -webkit-transform: rotate(255.6deg);
    -moz-transform: rotate(255.6deg);
    -ms-transform: rotate(255.6deg);
    -o-transform: rotate(255.6deg);
    transform: rotate(255.6deg); }
  .c100.p72 .bar {
    -webkit-transform: rotate(259.2deg);
    -moz-transform: rotate(259.2deg);
    -ms-transform: rotate(259.2deg);
    -o-transform: rotate(259.2deg);
    transform: rotate(259.2deg); }
  .c100.p73 .bar {
    -webkit-transform: rotate(262.8deg);
    -moz-transform: rotate(262.8deg);
    -ms-transform: rotate(262.8deg);
    -o-transform: rotate(262.8deg);
    transform: rotate(262.8deg); }
  .c100.p74 .bar {
    -webkit-transform: rotate(266.4deg);
    -moz-transform: rotate(266.4deg);
    -ms-transform: rotate(266.4deg);
    -o-transform: rotate(266.4deg);
    transform: rotate(266.4deg); }
  .c100.p75 .bar {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }
  .c100.p76 .bar {
    -webkit-transform: rotate(273.6deg);
    -moz-transform: rotate(273.6deg);
    -ms-transform: rotate(273.6deg);
    -o-transform: rotate(273.6deg);
    transform: rotate(273.6deg); }
  .c100.p77 .bar {
    -webkit-transform: rotate(277.2deg);
    -moz-transform: rotate(277.2deg);
    -ms-transform: rotate(277.2deg);
    -o-transform: rotate(277.2deg);
    transform: rotate(277.2deg); }
  .c100.p78 .bar {
    -webkit-transform: rotate(280.8deg);
    -moz-transform: rotate(280.8deg);
    -ms-transform: rotate(280.8deg);
    -o-transform: rotate(280.8deg);
    transform: rotate(280.8deg); }
  .c100.p79 .bar {
    -webkit-transform: rotate(284.4deg);
    -moz-transform: rotate(284.4deg);
    -ms-transform: rotate(284.4deg);
    -o-transform: rotate(284.4deg);
    transform: rotate(284.4deg); }
  .c100.p80 .bar {
    -webkit-transform: rotate(288deg);
    -moz-transform: rotate(288deg);
    -ms-transform: rotate(288deg);
    -o-transform: rotate(288deg);
    transform: rotate(288deg); }
  .c100.p81 .bar {
    -webkit-transform: rotate(291.6deg);
    -moz-transform: rotate(291.6deg);
    -ms-transform: rotate(291.6deg);
    -o-transform: rotate(291.6deg);
    transform: rotate(291.6deg); }
  .c100.p82 .bar {
    -webkit-transform: rotate(295.2deg);
    -moz-transform: rotate(295.2deg);
    -ms-transform: rotate(295.2deg);
    -o-transform: rotate(295.2deg);
    transform: rotate(295.2deg); }
  .c100.p83 .bar {
    -webkit-transform: rotate(298.8deg);
    -moz-transform: rotate(298.8deg);
    -ms-transform: rotate(298.8deg);
    -o-transform: rotate(298.8deg);
    transform: rotate(298.8deg); }
  .c100.p84 .bar {
    -webkit-transform: rotate(302.4deg);
    -moz-transform: rotate(302.4deg);
    -ms-transform: rotate(302.4deg);
    -o-transform: rotate(302.4deg);
    transform: rotate(302.4deg); }
  .c100.p85 .bar {
    -webkit-transform: rotate(306deg);
    -moz-transform: rotate(306deg);
    -ms-transform: rotate(306deg);
    -o-transform: rotate(306deg);
    transform: rotate(306deg); }
  .c100.p86 .bar {
    -webkit-transform: rotate(309.6deg);
    -moz-transform: rotate(309.6deg);
    -ms-transform: rotate(309.6deg);
    -o-transform: rotate(309.6deg);
    transform: rotate(309.6deg); }
  .c100.p87 .bar {
    -webkit-transform: rotate(313.2deg);
    -moz-transform: rotate(313.2deg);
    -ms-transform: rotate(313.2deg);
    -o-transform: rotate(313.2deg);
    transform: rotate(313.2deg); }
  .c100.p88 .bar {
    -webkit-transform: rotate(316.8deg);
    -moz-transform: rotate(316.8deg);
    -ms-transform: rotate(316.8deg);
    -o-transform: rotate(316.8deg);
    transform: rotate(316.8deg); }
  .c100.p89 .bar {
    -webkit-transform: rotate(320.4deg);
    -moz-transform: rotate(320.4deg);
    -ms-transform: rotate(320.4deg);
    -o-transform: rotate(320.4deg);
    transform: rotate(320.4deg); }
  .c100.p90 .bar {
    -webkit-transform: rotate(324deg);
    -moz-transform: rotate(324deg);
    -ms-transform: rotate(324deg);
    -o-transform: rotate(324deg);
    transform: rotate(324deg); }
  .c100.p91 .bar {
    -webkit-transform: rotate(327.6deg);
    -moz-transform: rotate(327.6deg);
    -ms-transform: rotate(327.6deg);
    -o-transform: rotate(327.6deg);
    transform: rotate(327.6deg); }
  .c100.p92 .bar {
    -webkit-transform: rotate(331.2deg);
    -moz-transform: rotate(331.2deg);
    -ms-transform: rotate(331.2deg);
    -o-transform: rotate(331.2deg);
    transform: rotate(331.2deg); }
  .c100.p93 .bar {
    -webkit-transform: rotate(334.8deg);
    -moz-transform: rotate(334.8deg);
    -ms-transform: rotate(334.8deg);
    -o-transform: rotate(334.8deg);
    transform: rotate(334.8deg); }
  .c100.p94 .bar {
    -webkit-transform: rotate(338.4deg);
    -moz-transform: rotate(338.4deg);
    -ms-transform: rotate(338.4deg);
    -o-transform: rotate(338.4deg);
    transform: rotate(338.4deg); }
  .c100.p95 .bar {
    -webkit-transform: rotate(342deg);
    -moz-transform: rotate(342deg);
    -ms-transform: rotate(342deg);
    -o-transform: rotate(342deg);
    transform: rotate(342deg); }
  .c100.p96 .bar {
    -webkit-transform: rotate(345.6deg);
    -moz-transform: rotate(345.6deg);
    -ms-transform: rotate(345.6deg);
    -o-transform: rotate(345.6deg);
    transform: rotate(345.6deg); }
  .c100.p97 .bar {
    -webkit-transform: rotate(349.2deg);
    -moz-transform: rotate(349.2deg);
    -ms-transform: rotate(349.2deg);
    -o-transform: rotate(349.2deg);
    transform: rotate(349.2deg); }
  .c100.p98 .bar {
    -webkit-transform: rotate(352.8deg);
    -moz-transform: rotate(352.8deg);
    -ms-transform: rotate(352.8deg);
    -o-transform: rotate(352.8deg);
    transform: rotate(352.8deg); }
  .c100.p99 .bar {
    -webkit-transform: rotate(356.4deg);
    -moz-transform: rotate(356.4deg);
    -ms-transform: rotate(356.4deg);
    -o-transform: rotate(356.4deg);
    transform: rotate(356.4deg); }
  .c100.p100 .bar {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); }
  .c100:hover {
    cursor: default; }
    .c100:hover > span {
      width: 3.33em;
      line-height: 3.33em;
      font-size: 0.3em;
      color: #ffdc00; }
    .c100:hover:after {
      top: 0.04em;
      left: 0.04em;
      width: 0.92em;
      height: 0.92em; }
  .c100.dark {
    background-color: #777; }
    .c100.dark .bar,
    .c100.dark .fill {
      border-color: #c6ff00 !important; }
    .c100.dark > span {
      color: #777; }
    .c100.dark:after {
      background-color: #666; }
    .c100.dark:hover > span {
      color: #c6ff00; }
  .c100.green .bar, .c100.green .fill {
    border-color: #4db53c !important; }
  .c100.green:hover > span {
    color: #4db53c; }
  .c100.green.dark .bar, .c100.green.dark .fill {
    border-color: #5fd400 !important; }
  .c100.green.dark:hover > span {
    color: #5fd400; }
  .c100.orange .bar, .c100.orange .fill {
    border-color: #dd9d22 !important; }
  .c100.orange:hover > span {
    color: #dd9d22; }
  .c100.orange.dark .bar, .c100.orange.dark .fill {
    border-color: #e08833 !important; }
  .c100.orange.dark:hover > span {
    color: #e08833; }

td.dt-control {
  background: url("https://www.datatables.net/examples/resources/details_open.png") no-repeat center center;
  cursor: pointer; }

tr.dt-hasChild td.dt-control {
  background: url("https://www.datatables.net/examples/resources/details_close.png") no-repeat center center; }

table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left; }

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center; }

table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right; }

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify; }

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap; }

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left; }

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center; }

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right; }

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify; }

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap; }

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left; }

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center; }

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right; }

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify; }

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap; }

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0; }

table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center; }

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left; }

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em; }

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0; }

table.dataTable > thead > tr > th:active,
table.dataTable > thead > tr > td:active {
  outline: none; }

table.dataTable > thead > tr > th:not(.sorting_disabled),
table.dataTable > thead > tr > td:not(.sorting_disabled) {
  padding-right: 30px; }

table.dataTable > thead .sorting,
table.dataTable > thead .sorting_asc,
table.dataTable > thead .sorting_desc,
table.dataTable > thead .sorting_asc_disabled,
table.dataTable > thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }

table.dataTable > thead .sorting:before, table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3; }

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  right: 1em;
  content: "↑"; }

table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "↓"; }

table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
  opacity: 1; }

table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  opacity: 0; }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody > table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

div.dataTables_scrollBody > table > thead .sorting:before,
div.dataTables_scrollBody > table > thead .sorting_asc:before,
div.dataTables_scrollBody > table > thead .sorting_desc:before,
div.dataTables_scrollBody > table > thead .sorting:after,
div.dataTables_scrollBody > table > thead .sorting_asc:after,
div.dataTables_scrollBody > table > thead .sorting_desc:after {
  display: none; }

div.dataTables_scrollBody > table > tbody tr:first-child th,
div.dataTables_scrollBody > table > tbody tr:first-child td {
  border-top: none; }

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box; }

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none; }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center !important; } }

table.dataTable.table-sm > thead > tr > th:not(.sorting_disabled) {
  padding-right: 20px; }

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em; }

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px; }

table.table-bordered.dataTable {
  border-right-width: 0; }

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0; }

table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 1px; }

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0; }

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0; }

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0; }

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: 0; }

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  position: relative;
  padding-left: 30px;
  cursor: pointer; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  top: 50%;
  left: 5px;
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #0275d8; }

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th.dtr-control:before {
  content: "-";
  background-color: #d33333; }

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th.dtr-control {
  padding-left: 27px; }

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th.dtr-control:before {
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px; }

table.dataTable.dtr-column > tbody > tr > td.dtr-control,
table.dataTable.dtr-column > tbody > tr > th.dtr-control,
table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer; }

table.dataTable.dtr-column > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 0.8em;
  width: 0.8em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #0275d8; }

table.dataTable.dtr-column > tbody > tr.parent td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: "-";
  background-color: #d33333; }

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em; }

table.dataTable > tbody > tr.child:hover {
  background: transparent !important; }

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none; }

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold; }

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em; }

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6); }

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em; }

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12; }

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea; }

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6); }

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%; } }

div.dtr-bs-modal table.table tr:first-child td {
  border-top: none; }

.font-size-10 {
  font-size: 0.625rem; }

.font-size-11 {
  font-size: 0.688rem; }

.font-size-12, #notifications-count, .col--label, .card-ideas__user-info, .criteria__type-title, .criteria__type-text, .criteria__add-comment, .award-score, .card-news__date, .pinned-news__date, .sidebar-news__date, .admin-menu-item-sub {
  font-size: 0.75rem; }

.font-size-13, .card-ideas__subtitle, .card-campaign__subtitle {
  font-size: 0.813rem; }

.font-size-14, label, .validationMessage, .text__validationMessage, .form__validationMessage, .card-ideas__user-name, .text-length-indicator, .award-label {
  font-size: 0.875rem; }

.font-size-15, .admin-menu-header, .admin-menu-item {
  font-size: 0.9375rem; }

.font-size-16, .ideafactory-info .card .card .card .card-header h2,
.ideafactory-info .card .card .card .card-header h2 a {
  font-size: 1rem; }

.font-size-18, .ideafactory-info .card .card .card-header h2, .navbar-nav, .card-rankings__header,
.card-rankings--primary .card-rankings__header {
  font-size: 1.125rem; }

.font-size-20, .card-ideas__title, .idea__heading-2, .idea__profile-name, .card-campaign__title {
  font-size: 1.25rem; }

.font-size-22 {
  font-size: 1.375rem; }

.font-size-24, .section-label {
  font-size: 1.5rem; }

.font-size-30 {
  font-size: 1.875rem; }

.font-size-32 {
  font-size: 2rem; }

.font-size-38 {
  font-size: 2.375rem; }

@media (min-width: 576px) {
  .font-size-sm-10 {
    font-size: 0.625rem; }
  .font-size-sm-11 {
    font-size: 0.688rem; }
  .font-size-sm-12 {
    font-size: 0.75rem; }
  .font-size-sm-13 {
    font-size: 0.813rem; }
  .font-size-sm-14 {
    font-size: 0.875rem; }
  .font-size-sm-15 {
    font-size: 0.9375rem; }
  .font-size-sm-16 {
    font-size: 1rem; }
  .font-size-sm-18 {
    font-size: 1.125rem; }
  .font-size-sm-20 {
    font-size: 1.25rem; }
  .font-size-sm-22 {
    font-size: 1.375rem; }
  .font-size-sm-24 {
    font-size: 1.5rem; }
  .font-size-sm-30 {
    font-size: 1.875rem; }
  .font-size-sm-32 {
    font-size: 2rem; }
  .font-size-sm-38 {
    font-size: 2.375rem; } }

@media (min-width: 768px) {
  .font-size-md-10 {
    font-size: 0.625rem; }
  .font-size-md-11 {
    font-size: 0.688rem; }
  .font-size-md-12 {
    font-size: 0.75rem; }
  .font-size-md-13 {
    font-size: 0.813rem; }
  .font-size-md-14 {
    font-size: 0.875rem; }
  .font-size-md-15 {
    font-size: 0.9375rem; }
  .font-size-md-16 {
    font-size: 1rem; }
  .font-size-md-18 {
    font-size: 1.125rem; }
  .font-size-md-20 {
    font-size: 1.25rem; }
  .font-size-md-22 {
    font-size: 1.375rem; }
  .font-size-md-24 {
    font-size: 1.5rem; }
  .font-size-md-30 {
    font-size: 1.875rem; }
  .font-size-md-32 {
    font-size: 2rem; }
  .font-size-md-38 {
    font-size: 2.375rem; } }

@media (min-width: 1024px) {
  .font-size-lg-10 {
    font-size: 0.625rem; }
  .font-size-lg-11 {
    font-size: 0.688rem; }
  .font-size-lg-12 {
    font-size: 0.75rem; }
  .font-size-lg-13 {
    font-size: 0.813rem; }
  .font-size-lg-14 {
    font-size: 0.875rem; }
  .font-size-lg-15 {
    font-size: 0.9375rem; }
  .font-size-lg-16 {
    font-size: 1rem; }
  .font-size-lg-18 {
    font-size: 1.125rem; }
  .font-size-lg-20 {
    font-size: 1.25rem; }
  .font-size-lg-22 {
    font-size: 1.375rem; }
  .font-size-lg-24 {
    font-size: 1.5rem; }
  .font-size-lg-30 {
    font-size: 1.875rem; }
  .font-size-lg-32 {
    font-size: 2rem; }
  .font-size-lg-38 {
    font-size: 2.375rem; } }

@media (min-width: 1200px) {
  .font-size-xl-10 {
    font-size: 0.625rem; }
  .font-size-xl-11 {
    font-size: 0.688rem; }
  .font-size-xl-12 {
    font-size: 0.75rem; }
  .font-size-xl-13 {
    font-size: 0.813rem; }
  .font-size-xl-14 {
    font-size: 0.875rem; }
  .font-size-xl-15 {
    font-size: 0.9375rem; }
  .font-size-xl-16 {
    font-size: 1rem; }
  .font-size-xl-18 {
    font-size: 1.125rem; }
  .font-size-xl-20 {
    font-size: 1.25rem; }
  .font-size-xl-22 {
    font-size: 1.375rem; }
  .font-size-xl-24 {
    font-size: 1.5rem; }
  .font-size-xl-30 {
    font-size: 1.875rem; }
  .font-size-xl-32 {
    font-size: 2rem; }
  .font-size-xl-38 {
    font-size: 2.375rem; } }

/**
 Generate utility classes for backgrounds, texts and badges (bg-color, text-color, badge-color).
 If we put these colors to the theme-colors map, the full stack of bootstrap helper classes is created.
 (buttons, tables, alerts, list-groups).
 */
.badge-ideafactory-black {
  color: #fff;
  background-color: #111111; }
  a.badge-ideafactory-black:hover, a.badge-ideafactory-black:focus {
    color: #fff;
    background-color: black; }
  a.badge-ideafactory-black:focus, a.badge-ideafactory-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5); }

.badge-ideafactory-grey {
  color: #fff;
  background-color: #333333; }
  a.badge-ideafactory-grey:hover, a.badge-ideafactory-grey:focus {
    color: #fff;
    background-color: #1a1a1a; }
  a.badge-ideafactory-grey:focus, a.badge-ideafactory-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.badge-ideafactory-grey-light {
  color: #212529;
  background-color: #b4b4b4; }
  a.badge-ideafactory-grey-light:hover, a.badge-ideafactory-grey-light:focus {
    color: #212529;
    background-color: #9b9b9b; }
  a.badge-ideafactory-grey-light:focus, a.badge-ideafactory-grey-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(180, 180, 180, 0.5); }

.badge-ideafactory-grey-lighter {
  color: #212529;
  background-color: #ebebeb; }
  a.badge-ideafactory-grey-lighter:hover, a.badge-ideafactory-grey-lighter:focus {
    color: #212529;
    background-color: #d2d2d2; }
  a.badge-ideafactory-grey-lighter:focus, a.badge-ideafactory-grey-lighter.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5); }

.badge-ideafactory-grey-lightest {
  color: #212529;
  background-color: #f6f6f6; }
  a.badge-ideafactory-grey-lightest:hover, a.badge-ideafactory-grey-lightest:focus {
    color: #212529;
    background-color: #dddddd; }
  a.badge-ideafactory-grey-lightest:focus, a.badge-ideafactory-grey-lightest.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(246, 246, 246, 0.5); }

.text-ideafactory-black {
  color: #111111 !important; }

a.text-ideafactory-black:hover, a.text-ideafactory-black:focus {
  color: black !important; }

.text-ideafactory-grey {
  color: #333333 !important; }

a.text-ideafactory-grey:hover, a.text-ideafactory-grey:focus {
  color: #0d0d0d !important; }

.text-ideafactory-grey-light, .idea-user-name--anonym, .card-news__date, .pinned-news__date, .sidebar-news__date, .admin-menu-item-sub > .fa {
  color: #b4b4b4 !important; }

a.text-ideafactory-grey-light:hover, a.idea-user-name--anonym:hover, a.card-news__date:hover, a.pinned-news__date:hover, a.sidebar-news__date:hover, .admin-menu-item-sub > a.fa:hover, a.text-ideafactory-grey-light:focus, a.idea-user-name--anonym:focus, a.card-news__date:focus, a.pinned-news__date:focus, a.sidebar-news__date:focus, .admin-menu-item-sub > a.fa:focus {
  color: #8e8e8e !important; }

.text-ideafactory-grey-lighter {
  color: #ebebeb !important; }

a.text-ideafactory-grey-lighter:hover, a.text-ideafactory-grey-lighter:focus {
  color: #c5c5c5 !important; }

.text-ideafactory-grey-lightest {
  color: #f6f6f6 !important; }

a.text-ideafactory-grey-lightest:hover, a.text-ideafactory-grey-lightest:focus {
  color: #d0d0d0 !important; }

.bg-ideafactory-black {
  background-color: #111111 !important; }

a.bg-ideafactory-black:hover, a.bg-ideafactory-black:focus,
button.bg-ideafactory-black:hover,
button.bg-ideafactory-black:focus {
  background-color: black !important; }

.bg-ideafactory-grey, .carousel-inner--grey {
  background-color: #333333 !important; }

a.bg-ideafactory-grey:hover, a.carousel-inner--grey:hover, a.bg-ideafactory-grey:focus, a.carousel-inner--grey:focus,
button.bg-ideafactory-grey:hover,
button.carousel-inner--grey:hover,
button.bg-ideafactory-grey:focus,
button.carousel-inner--grey:focus {
  background-color: #1a1a1a !important; }

.bg-ideafactory-grey-light {
  background-color: #b4b4b4 !important; }

a.bg-ideafactory-grey-light:hover, a.bg-ideafactory-grey-light:focus,
button.bg-ideafactory-grey-light:hover,
button.bg-ideafactory-grey-light:focus {
  background-color: #9b9b9b !important; }

.bg-ideafactory-grey-lighter {
  background-color: #ebebeb !important; }

a.bg-ideafactory-grey-lighter:hover, a.bg-ideafactory-grey-lighter:focus,
button.bg-ideafactory-grey-lighter:hover,
button.bg-ideafactory-grey-lighter:focus {
  background-color: #d2d2d2 !important; }

.bg-ideafactory-grey-lightest, .evaluation_idea-details, .col--card-news-text, .row--pinned-news, .sidebar-news, .sidebar-countdown, .row--active-campaign, .card--admin-dashboard .card-footer, .evaluation-idea, .widget-countdown {
  background-color: #f6f6f6 !important; }

a.bg-ideafactory-grey-lightest:hover, a.evaluation_idea-details:hover, a.col--card-news-text:hover, a.row--pinned-news:hover, a.sidebar-news:hover, a.sidebar-countdown:hover, a.row--active-campaign:hover, .card--admin-dashboard a.card-footer:hover, a.evaluation-idea:hover, a.widget-countdown:hover, a.bg-ideafactory-grey-lightest:focus, a.evaluation_idea-details:focus, a.col--card-news-text:focus, a.row--pinned-news:focus, a.sidebar-news:focus, a.sidebar-countdown:focus, a.row--active-campaign:focus, .card--admin-dashboard a.card-footer:focus, a.evaluation-idea:focus, a.widget-countdown:focus,
button.bg-ideafactory-grey-lightest:hover,
button.evaluation_idea-details:hover,
button.col--card-news-text:hover,
button.row--pinned-news:hover,
button.sidebar-news:hover,
button.sidebar-countdown:hover,
button.row--active-campaign:hover,
.card--admin-dashboard button.card-footer:hover,
button.evaluation-idea:hover,
button.widget-countdown:hover,
button.bg-ideafactory-grey-lightest:focus,
button.evaluation_idea-details:focus,
button.col--card-news-text:focus,
button.row--pinned-news:focus,
button.sidebar-news:focus,
button.sidebar-countdown:focus,
button.row--active-campaign:focus,
.card--admin-dashboard button.card-footer:focus,
button.evaluation-idea:focus,
button.widget-countdown:focus {
  background-color: #dddddd !important; }

.bl, .bl-primary, .notification--evaluation, .sidebar-news, .bl-secondary, .bl-success, .bl-info, .bl-warning, .bl-danger, .bl-light, .bl-dark, .bl-ideafactory-admin {
  border-left: 10px solid #ef476f; }

.bl-primary, .notification--evaluation, .sidebar-news {
  border-color: #ef476f !important; }

.bl-secondary {
  border-color: #007a9b !important; }

.bl-success {
  border-color: #28a745 !important; }

.bl-info {
  border-color: #17a2b8 !important; }

.bl-warning {
  border-color: #ffc107 !important; }

.bl-danger {
  border-color: #dc3545 !important; }

.bl-light {
  border-color: #f8f9fa !important; }

.bl-dark {
  border-color: #343a40 !important; }

.bl-ideafactory-admin {
  border-color: #404040 !important; }

.icon-state, .icon-state--red, .icon-state--green, .icon-state--blue, .icon-state--yellow, .icon-state--submission, .icon-state--submitted, .icon-state--evaluation, .icon-state--evaluated, .icon-state--implementation, .icon-state--implemented, .icon-state--development, .icon-state--conception, .icon-state--canceled, .icon-state--conceptionCanceled, .icon-state--notnew, .icon-state--unknown {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #b4b4b4;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

.bg-state--red {
  background-color: #DA2032; }

.bl-state--red {
  border-left: 5px solid #DA2032; }

.br-state--red {
  border-right: 5px solid #DA2032; }

.icon-state--red {
  background-color: #DA2032; }

.bg-state--green {
  background-color: #62B339; }

.bl-state--green {
  border-left: 5px solid #62B339; }

.br-state--green {
  border-right: 5px solid #62B339; }

.icon-state--green {
  background-color: #62B339; }

.bg-state--blue {
  background-color: #007A9B; }

.bl-state--blue {
  border-left: 5px solid #007A9B; }

.br-state--blue {
  border-right: 5px solid #007A9B; }

.icon-state--blue {
  background-color: #007A9B; }

.bg-state--yellow {
  background-color: #FFDC00; }

.bl-state--yellow {
  border-left: 5px solid #FFDC00; }

.br-state--yellow {
  border-right: 5px solid #FFDC00; }

.icon-state--yellow {
  background-color: #FFDC00; }

.bg-state--submission {
  background-color: #FFDC00; }

.bl-state--submission {
  border-left: 5px solid #FFDC00; }

.br-state--submission {
  border-right: 5px solid #FFDC00; }

.icon-state--submission {
  background-color: #FFDC00; }

.bg-state--submitted {
  background-color: #FFDC00; }

.bl-state--submitted {
  border-left: 5px solid #FFDC00; }

.br-state--submitted {
  border-right: 5px solid #FFDC00; }

.icon-state--submitted {
  background-color: #FFDC00; }

.bg-state--evaluation {
  background-color: #FFDC00; }

.bl-state--evaluation {
  border-left: 5px solid #FFDC00; }

.br-state--evaluation {
  border-right: 5px solid #FFDC00; }

.icon-state--evaluation {
  background-color: #FFDC00; }

.bg-state--evaluated {
  background-color: #FFDC00; }

.bl-state--evaluated {
  border-left: 5px solid #FFDC00; }

.br-state--evaluated {
  border-right: 5px solid #FFDC00; }

.icon-state--evaluated {
  background-color: #FFDC00; }

.bg-state--implementation {
  background-color: #FFDC00; }

.bl-state--implementation {
  border-left: 5px solid #FFDC00; }

.br-state--implementation {
  border-right: 5px solid #FFDC00; }

.icon-state--implementation {
  background-color: #FFDC00; }

.bg-state--implemented {
  background-color: #FFDC00; }

.bl-state--implemented {
  border-left: 5px solid #FFDC00; }

.br-state--implemented {
  border-right: 5px solid #FFDC00; }

.icon-state--implemented {
  background-color: #FFDC00; }

.bg-state--development {
  background-color: #FFDC00; }

.bl-state--development {
  border-left: 5px solid #FFDC00; }

.br-state--development {
  border-right: 5px solid #FFDC00; }

.icon-state--development {
  background-color: #FFDC00; }

.bg-state--conception {
  background-color: #FFDC00; }

.bl-state--conception {
  border-left: 5px solid #FFDC00; }

.br-state--conception {
  border-right: 5px solid #FFDC00; }

.icon-state--conception {
  background-color: #FFDC00; }

.bg-state--canceled {
  background-color: #FFDC00; }

.bl-state--canceled {
  border-left: 5px solid #FFDC00; }

.br-state--canceled {
  border-right: 5px solid #FFDC00; }

.icon-state--canceled {
  background-color: #FFDC00; }

.bg-state--conceptionCanceled {
  background-color: #FFDC00; }

.bl-state--conceptionCanceled {
  border-left: 5px solid #FFDC00; }

.br-state--conceptionCanceled {
  border-right: 5px solid #FFDC00; }

.icon-state--conceptionCanceled {
  background-color: #FFDC00; }

.bg-state--notnew {
  background-color: #FFDC00; }

.bl-state--notnew {
  border-left: 5px solid #FFDC00; }

.br-state--notnew {
  border-right: 5px solid #FFDC00; }

.icon-state--notnew {
  background-color: #FFDC00; }

.bg-state--unknown {
  background-color: #CCCCCC; }

.bl-state--unknown {
  border-left: 5px solid #CCCCCC; }

.br-state--unknown {
  border-right: 5px solid #CCCCCC; }

.icon-state--unknown {
  background-color: #CCCCCC; }

body {
  margin: 0;
  padding: 0; }

.container-fluid {
  max-width: 1440px; }

.row--cols-equal-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.row--cols-equal-height > [class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.white-space-pre {
  white-space: pre-line; }

.actionbar {
  width: 100%; }

.actionbar-wrapper::before {
  content: '';
  display: block;
  width: 100%;
  min-height: 120px;
  border: 1px solid transparent; }

@media (min-width: 768px) {
  .actionbar {
    position: fixed;
    bottom: 0;
    left: 0;
    max-height: 102px;
    z-index: 998; } }

h1 > a, h1 > a:hover,
h2 > a, h2 > a:hover,
h3 > a, h3 > a:hover,
h4 > a, h4 > a:hover,
h5 > a, h5 > a:hover,
h6 > a, h6 > a:hover {
  color: #111111; }

.text-label, label {
  color: #B5B5B5; }

label {
  text-transform: uppercase; }

.custom-control-label--inline {
  padding-top: 0.1rem; }

.clickable, .collapse-toggle {
  cursor: pointer; }

.font-family-heading {
  font-family: "Merriweather Sans", sans-serif; }

.font-family-body {
  font-family: "Lato", sans-serif; }

#header {
  background-color: #ef476f; }

.col--content-bg {
  background-image: url("../images/ideafactory-bg.svg");
  background-repeat: no-repeat;
  background-position: left top; }

.sidebar-box {
  margin-bottom: 64px; }

.sidebar-box--default {
  background-color: #f6f6f6 !important; }

.sidebar-box--postpone {
  background-color: #fef0f3 !important; }

.sidebar-box--handover {
  background-color: #e8faff !important; }

.sidebar-box--collaboration {
  background-color: #007a9b !important; }

.sidebar-box--countdown {
  background-color: #007a9b !important; }

.navbar-nav {
  margin-top: 2rem;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 700; }

.navbar-nav .nav-link {
  text-transform: uppercase; }

.navbar-light .navbar-nav .nav-item {
  padding-left: 1rem;
  padding-right: 1rem; }

.navbar-brand {
  order: 1; }

.navbar-toggler {
  border: 0;
  order: 2; }

.navbar-toggler:focus {
  outline: transparent none 0; }

.navbar-collapse {
  order: 3; }

@media (max-width: 1199.98px) {
  .navbar-collapse {
    order: 5; } }

.navbar-profile {
  order: 4; }

@media (min-width: 1200px) {
  .navbar-nav {
    margin-top: 0;
    min-height: 98px; }
  .navbar-light .navbar-nav .nav-item {
    padding-left: 1rem;
    padding-right: 1rem; }
  .navbar-light .navbar-nav .nav-link {
    position: relative; }
  .navbar-light .navbar-nav .nav-link.active-path {
    color: #111111; }
  .navbar-light .navbar-nav .nav-link.active-path:before,
  .navbar-light .navbar-nav .nav-link:hover:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.975rem;
    height: 8px;
    width: 100%;
    background-color: #111111; } }

.btn-secondary--nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.px-grid {
  padding-left: 30px;
  padding-right: 30px; }

.mx-grid-reset {
  margin-left: -15px;
  margin-right: -15px; }

.row--inner {
  margin-left: 0;
  margin-right: 0; }

.row--odd {
  background-color: #f6f6f6; }

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1; }

.overlay {
  position: relative; }

.overlay > * {
  z-index: 2; }

#notifications {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1440px;
  z-index: 2000; }

#notifications-count {
  position: absolute;
  right: -10px;
  top: 3px;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  display: block;
  text-align: center;
  padding-top: 2px;
  z-index: 2; }

.notification {
  border-top: 1px solid #b4b4b4;
  padding: .7rem 0; }

.notification--evaluation {
  border-color: #ef476f !important; }

.notification__close {
  position: relative;
  top: -3px; }

.validationMessage, .text__validationMessage, .form__validationMessage {
  display: block;
  color: #721c24;
  padding-top: .1rem;
  padding-left: 0.75rem; }

.form-group-autocomplete {
  position: relative; }

.autocomplete-reset {
  position: absolute;
  top: .4rem;
  right: .4rem; }

.knockout-autocomplete.after-menu {
  width: 100%;
  z-index: 999; }

.knockout-autocomplete.menu {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15); }

.btn-default {
  color: #007a9b;
  border: 1px solid #007a9b; }

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  color: #007a9b; }

.btn--login {
  position: relative;
  top: -1.7rem; }

.section-label,
.col--label {
  font-family: "Lato", sans-serif;
  color: #b4b4b4;
  text-transform: uppercase; }

.col--label {
  padding-top: .4rem;
  margin-bottom: .4rem; }

.card-ideas {
  border: 0px solid transparent;
  position: relative;
  background-color: #f6f6f6; }

.card-ideas__icons {
  position: absolute;
  top: -4px;
  left: -4px; }

.card-ideas__likes {
  padding: 3px 7px 4px 7px;
  border-radius: 0.25rem; }

.badge-development {
  background-color: #ef476f !important; }

.card-ideas__image {
  height: 200px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card-ideas__title {
  min-height: 48px; }

.card-ideas__user-name {
  display: block;
  font-weight: bold;
  margin-bottom: .1rem; }

.card-ideas__user-info {
  display: block; }

.card-ideas__body {
  padding-bottom: 1.5rem; }

.card-ideas__footer {
  background-color: #ebebeb;
  padding-top: .275rem;
  padding-bottom: .4rem; }

.card-ideas__state {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: .2rem;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

.card-ideas--development-idea {
  background-color: #fef0f3; }

.card-ideas--development-idea .card-ideas__footer {
  background-color: #fcd8e1; }

.ideas-footer {
  background-image: url("../images/bg-footer.png");
  background-size: contain;
  background-position: center center; }

.idea__image {
  height: 270px;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-position: center center; }

.comment-images-carousel {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  max-height: 3000px; }

.comment-images-carousel .idea__image {
  height: 200px; }

.col--sidebar .comment-images-carousel .idea__image {
  height: 250px; }

@media (min-width: 576px) {
  .idea__image {
    height: 370px; } }

@media (min-width: 768px) {
  .idea__image {
    height: 470px; }
  .comment-images-carousel .idea__image {
    height: 300px; } }

.idea__attachment-actions {
  position: absolute;
  left: -8px;
  top: -10px; }

.idea__image-thumbnail {
  display: block;
  height: 170px;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-position: center center; }

@media (min-width: 576px) {
  .idea__image-thumbnail {
    height: 200px; } }

@media (min-width: 768px) {
  .idea__image-thumbnail {
    height: 220px; } }

.idea__evaluation {
  margin-top: 2rem;
  border-left: 10px solid #ef476f;
  background-color: #ebebeb; }

.idea__implementation-description {
  margin-top: 2rem;
  border-left: 10px solid #007a9b;
  background-color: #ebebeb; }

.text-length-indicator {
  padding-left: 8px;
  position: relative;
  top: -0.8rem;
  margin-bottom: 1.2rem; }

.text-length-indicator__label, .text-length-indicator__color {
  vertical-align: middle; }

.text-length-indicator__color {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.5rem;
  width: 15px;
  height: 15px; }

.text-length-indicator__color--small {
  background-color: #dc3545; }

.text-length-indicator__color--medium {
  background-color: #ffc107; }

.text-length-indicator__color--large {
  background-color: #28a745; }

.evaluation__idea-image {
  min-height: 210px;
  background-image: url("../images/dummy.png");
  background-size: cover; }

.criteria {
  border-bottom: 1px solid #ebebeb; }

.criteria__type-title {
  line-height: 1.3em;
  color: #007a9b; }

.criteria__type-text {
  line-height: 1.3em;
  color: #B5B5B5; }

.criteria__add-comment {
  font-style: italic;
  color: #007a9b; }

.rating__star {
  color: #C7C7C7; }

.rating__star--active {
  color: #ef476f; }

.user-image, .card-rankings__user-image {
  display: inline-block;
  width: 48px;
  height: 48px;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-color: #007a9b; }

.user-image--circle, .user-image--circle-medium, .user-image--circle-big, .card-rankings__user-image {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-right: 0.5rem; }

.user-image--circle-medium {
  width: 70px;
  height: 70px; }

.user-image--circle-big {
  width: 120px;
  height: 120px; }

.user-image--square-100 {
  width: 100px;
  height: 100px; }

.user-image--header {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 52px;
  height: 52px;
  position: relative;
  top: 4px; }

.user-image--profile {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 220px;
  height: 220px;
  margin: 1rem 1.5rem; }

.fa--remove-user {
  position: absolute;
  top: 50%;
  right: 10%;
  margin-top: -.6rem;
  z-index: 10; }

.card--profile {
  border: 1px solid #E5E5E5;
  background-color: #F6F6F6; }

.awards {
  border: 1px solid #E5E5E5;
  background-color: #F6F6F6; }

.award-label {
  text-align: center;
  font-weight: bold;
  line-height: 1.1; }

@media (min-width: 576px) {
  .award-label {
    min-height: 51px;
    white-space: pre-wrap;
    max-width: 110px;
    padding: 0 10px 0 10px;
    margin-left: auto;
    margin-right: auto; } }

.award-score {
  text-align: center; }

.award-image {
  background-image: url("../images/awards/Award1_bronze.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  max-width: 80px;
  height: 110px;
  margin-left: auto;
  margin-right: auto; }

.award-image-evaluator--gold {
  background-image: url("../images/awards/Award1_gold.png"); }

.award-image-evaluator--silver {
  background-image: url("../images/awards/Award1_silber.png"); }

.award-image-evaluator--bronze {
  background-image: url("../images/awards/Award1_bronze.png"); }

.award-image-perfectIdeas--gold {
  background-image: url("../images/awards/Award2_gold.png"); }

.award-image-perfectIdeas--silver {
  background-image: url("../images/awards/Award2_silber.png"); }

.award-image-perfectIdeas--bronze {
  background-image: url("../images/awards/Award2_bronze.png"); }

.award-image-ideasSubmitted--gold {
  background-image: url("../images/awards/Award3_gold.png"); }

.award-image-ideasSubmitted--silver {
  background-image: url("../images/awards/Award3_silber.png"); }

.award-image-ideasSubmitted--bronze {
  background-image: url("../images/awards/Award3_bronze.png"); }

.award-image-ideasImplemented--gold {
  background-image: url("../images/awards/Award4_gold.png"); }

.award-image-ideasImplemented--silver {
  background-image: url("../images/awards/Award4_silber.png"); }

.award-image-ideasImplemented--bronze {
  background-image: url("../images/awards/Award4_bronze.png"); }

.award-image-comments--gold {
  background-image: url("../images/awards/Award5_gold.png"); }

.award-image-comments--silver {
  background-image: url("../images/awards/Award5_silber.png"); }

.award-image-comments--bronze {
  background-image: url("../images/awards/Award5_bronze.png"); }

.award-image-codeveloper--gold {
  background-image: url("../images/awards/Award6_gold.png"); }

.award-image-codeveloper--silver {
  background-image: url("../images/awards/Award6_silber.png"); }

.award-image-codeveloper--bronze {
  background-image: url("../images/awards/Award6_bronze.png"); }

.col--card-news {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 1rem; }

.col--card-news-image {
  background-color: #ef476f !important; }

@media (min-width: 576px) {
  .card-news {
    min-height: 300px; } }

.card-news,
.card-news__header,
.card-news__footer {
  background-color: transparent; }

.card-news__title {
  min-height: 48px; }

.card-news__image {
  height: 100px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.pinned-news,
.pinned-news__header,
.pinned-news__footer {
  background-color: transparent; }

.pinned-news__title {
  min-height: 48px; }

.pinned-news__image {
  height: 100%;
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.sidebar-news {
  border-top: transparent;
  border-right: transparent;
  border-bottom: transparent; }

.sidebar-news,
.sidebar-news__header,
.sidebar-news__footer {
  background-color: transparent; }

.news-detail__teaser_image {
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.col--card-campaign {
  margin-bottom: 3rem; }

.card-campaign {
  border: 0px solid transparent;
  position: relative;
  background-color: #f6f6f6; }

.card-campaign__icons {
  position: absolute;
  top: -4px;
  left: -4px; }

.card-campaign__body {
  padding-bottom: 1.5rem; }

.card-campaign__footer {
  background-color: #f6f6f6;
  padding-top: .5rem;
  padding-bottom: 1rem; }

.card-campaign__title {
  min-height: 48px; }

.card-campaign__subtitle {
  color: #333333; }

.card-campaign__image {
  height: 180px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.active-campaign__background {
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid transparent;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1; }

.card--active-campaign,
.active-campaign,
.active-campaign__header,
.active-campaign__footer {
  background-color: transparent; }

.active-campaign__title {
  min-height: 56px; }

.active-campaign__image {
  height: 100%;
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.sidebar-campaign {
  border-top: transparent;
  border-right: transparent;
  border-bottom: transparent; }

.sidebar-campaign,
.sidebar-campaign__header,
.sidebar-campaign__footer {
  background-color: transparent; }

.campaign-detail__teaser_image {
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.comments,
.shadow-box {
  border: 1px solid #f6f6f6;
  border-radius: 10px;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.16);
  padding: 1rem .8rem; }

.comment {
  border-top: 1px solid #ebebeb;
  padding-top: 1.2rem; }

.comments .comment:first-child {
  border-top: 0px solid #ebebeb;
  padding-top: 0; }

.comment-level-0 {
  margin-left: 0; }

.comment-level-1 {
  margin-left: 4.4375rem; }

.comment-level-2 {
  margin-left: 8.875rem; }

.comment-level-3 {
  margin-left: 13.3125rem; }

.comment-level-4 {
  margin-left: 17.75rem; }

.carousel {
  margin-bottom: 5rem; }

.carousel-indicators {
  bottom: -3.5rem; }

.carousel-indicators > li {
  border-radius: 50%; }

.carousel-item--campaign {
  height: auto; }

@media (min-width: 576px) {
  .carousel-item--campaign {
    height: 476px; } }

.nav-tabs .nav-link {
  background-color: #f6f6f6; }

.page-link,
.page-item.disabled .page-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  margin-left: .2rem;
  margin-right: .2rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.drag-handle {
  cursor: move; }

@media (max-width: 767.98px) {
  .page-item.ks-boundary-first,
  .page-item.ks-boundary-last {
    display: none; } }

.admin-content {
  min-height: 800px; }

.admin-menu-header {
  letter-spacing: 2px;
  background-color: #333333; }

.admin-menu-item {
  display: block;
  margin-top: 1px; }

.admin-menu-item:hover,
.admin-menu-item.active-path {
  background-color: #333333; }

.admin-menu-item-sub {
  display: block;
  margin-top: 1px; }

.admin-menu-item-sub:hover,
.admin-menu-item-sub.active-path {
  background-color: #333333; }

.not-clickable {
  pointer-events: none;
  cursor: default; }

.row--dashboard-list-item {
  border-bottom: 1px solid #DBDBDB; }

.card--admin-dashboard {
  border: 1px solid #f6f6f6;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.16); }

.card--admin-dashboard .card-header {
  background-color: #007a9b;
  color: white; }

.card--admin-dashboard .card-body {
  height: 390px;
  max-height: 390px;
  overflow-y: auto; }

.card--admin-dashboard .card-footer {
  min-height: 56px; }

.card-category {
  border: 0px solid transparent;
  position: relative;
  background-color: #f6f6f6; }

.card-category__image {
  height: 200px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card-category .card-footer {
  background-color: #ebebeb; }

.active-campaign__background::after {
  z-index: 2; }

.card-rankings__header,
.card-rankings--primary .card-rankings__header {
  background-color: #ef476f;
  color: #fff;
  text-align: center;
  font-weight: bold; }

.card-rankings--primary .my-list-entry {
  background-color: #fef0f3; }

.card-rankings--secondary .card-rankings__header {
  background-color: #007a9b;
  color: #fff; }

.card-rankings--secondary .my-list-entry {
  background-color: #d8f7ff; }

.card-rankings__body {
  padding: 0; }

.card-rankings__ranking {
  min-width: 3rem; }

.card-rankings__user-image {
  margin-right: 0;
  overflow: hidden; }

.card-rankings__dots {
  letter-spacing: 2px;
  padding-bottom: .8rem;
  font-size: 3rem;
  line-height: 2rem;
  color: #b4b4b4;
  position: relative;
  top: -.3rem; }

.card-rankings__value {
  min-width: 3rem;
  text-align: right; }

.ranking-row {
  padding-top: .5rem;
  padding-bottom: .2rem; }

.ranking-row--odd {
  background-color: #F6F6F6; }

.ranking-row--even {
  background-color: #EAEAEA; }

.count__value {
  font-family: "Merriweather Sans", sans-serif;
  font-size: 3.75rem; }

#carousel-tutorial-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 10000; }

#carousel-tutorial {
  max-width: 960px;
  max-height: 100%;
  margin-bottom: 0; }

#tutorial-dissmiss {
  position: absolute;
  right: .3rem;
  top: .3rem;
  z-index: 10001; }

#tutorial-dissmiss label {
  position: relative;
  top: 1px; }

@media all and (-ms-high-contrast: none) {
  .navbar-nav--ideafactory {
    height: 90px; } }

/*
 Bug fix for data tables button drop down menu
 */
.dt-button-collection .dropdown-menu {
  display: block; }

.menu-separator {
  height: 1px; }

.menu-separator:before {
  content: '';
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  width: 100%;
  line-height: 1px;
  height: 1px; }

@media print {
  .carousel-item.idea__image.active, .user-image, .card-rankings__user-image,
  .evaluation__idea-image,
  .active-campaign__image,
  .card-campaign__image,
  .card-news__image,
  .pinned-news__image,
  .idea__image-thumbnail,
  .idea__image,
  .card-ideas__image,
  .news-detail__teaser_image,
  .img-fluid {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari, Edge */
    color-adjust: exact !important;
    /* Firefox */
    print-color-adjust: exact !important;
    /* Firefox 97+, Safari 15.4+ */ } }

.ideafactory-logo {
  fill: #007a9b; }

.ideafactory-logo-text {
  fill: #111111; }

.assessments-average,
.community-comments {
  background-color: #06d6a0 !important; }

.community-users {
  background-color: #b4b4b4 !important; }

.col--sidebar-create {
  background-color: #d8f7ff !important; }

.evaluation-idea {
  position: relative;
  top: -.9rem;
  z-index: 10; }

.evaluation-idea-icons {
  position: relative;
  z-index: 101; }

.print-page-break-inside-avoid {
  page-break-inside: avoid; }

.print-page-break-before {
  page-break-before: always; }

.print-page-break-after {
  page-break-after: always; }

@media print {
  .d-print-float-none {
    float: none !important; }
  .d-print-mt-2 {
    margin-top: 2rem; } }

.collapse--show {
  display: inline-block; }

.collapse--hide {
  display: none; }

.btn[aria-expanded="true"] .collapse--show {
  display: none; }

.btn[aria-expanded="true"] .collapse--hide {
  display: inline-block; }

.admin-contact.ui-sortable-handle {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab; }

.admin-contact.ui-sortable-handle a {
  cursor: pointer; }
