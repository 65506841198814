$fa-font-path:        "~font-awesome/fonts" !default;

@import "~font-awesome/scss/variables";
@import "~font-awesome/scss/mixins";
@import "~font-awesome/scss/path";
@import "~font-awesome/scss/core";
@import "~font-awesome/scss/larger";
@import "~font-awesome/scss/fixed-width";
@import "~font-awesome/scss/list";
@import "~font-awesome/scss/bordered-pulled";
@import "~font-awesome/scss/animated";
@import "~font-awesome/scss/rotated-flipped";
@import "~font-awesome/scss/stacked";
@import "~font-awesome/scss/icons";
@import "~font-awesome/scss/screen-reader";

.#{$fa-css-prefix}-sm { font-size: 0.5em; }
