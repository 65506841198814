.loading-spinner {
  display: block;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-right: -10px;
}

body.loading-overlay::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height:100%;
  z-index: 2001;
}